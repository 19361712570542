import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import russianFlag from './../../../assets/russian-flag.png'
import americanFlag from './../../../assets/american-flag.png'

const Btn = styled.button`
  width: 85px;
  height: 30px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  outline: none;
  border: 1px solid #949494;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0.8;
  font-size: 14px;
  padding-top: 0px;
  padding-right: 5px;

  img{
    width: 20px;
    height: 20px;
    transform: scale(0.8);
    filter: contrast(1);
    margin: 0 5px;
    display: block;
  }

  @media (max-width: 577px){
    width: 70px;
  }
`


const ButtonFlag = ({text, link}) => {

  const { i18n } = useTranslation()

  function clickLang(lang) {
    i18n.changeLanguage(lang)
  }

  const cookie = Cookies.get('i18next')


  return (
    <>
    {cookie === 'ru' ?
    <Btn onClick={() =>clickLang('en') }>
      <img src={russianFlag} alt="flag" />
      <div>
        RU
      </div>
    </Btn>
    :
    <Btn onClick={() =>clickLang('ru') }>
      <img src={americanFlag} alt="flag" />
      <div>
        EN 
      </div>
    </Btn>
    } 
    </>
  )
}

export default ButtonFlag