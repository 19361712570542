import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

const Btn = styled.button`
margin: 3rem 1rem;
width: 175px;
height: 40px;
background: #F5F5F5;
border: 1px solid #E1DFD2;
border-radius: 10px;
font-family: 'Palatino';
font-size: 18px;
padding: -0,5rem 1rem;

span{
  margin: 0;
  font-size: 14px;
}

@media (max-width: 577px){
  margin: 0;
}
@media (max-width: 380px){
  width: 150px;
  margin: 0;
}
`

const ButtonSold = () => {

  const { t } = useTranslation()

  return (
    <Btn>
        {t('myWorkSold')} 
    </Btn>
  )
}

export default ButtonSold