import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import {CustomLink} from '../../CustomLinkSettings'

const Menu = styled.ul`
  max-width: 615px;
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  margin-left: 25px;
  box-sizing: border-box;


.MenuItem{
  width: 160px;
  height: 24px;
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  margin: 0 0rem;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: rgba(189,169,158,0.1);
  font-weight: 400;
  font-family: 'Palatino';
}
  @media (max-width: 577px ){
    margin-left: 0;
    padding: 0 10px;
    width: 100%;
  }
`

const OrderMenu = () => {

  const { t } = useTranslation()

  return (
    <Menu>
        <CustomLink to='/account/SettingsMenu' className='MenuItem'>{t('general')}</CustomLink>
        <CustomLink to='/account/SettingsMenu/Addition' className='MenuItem'>{t('addition')}</CustomLink>
        {/* <CustomLink to='/account/SettingsMenu/Subs' className='MenuItem'>{t('payment')}</CustomLink> */}
    </Menu>
  )
}

export default OrderMenu