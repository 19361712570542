import styled from 'styled-components/macro';

const Root = styled.div`
    display: flex;
    justify-content: center;

    .loader-2 {
        display: block;
        height: 100px;
        width: 100px;
        -webkit-animation: loader-2-1 3s linear infinite;
                animation: loader-2-1 3s linear infinite;
    }
    @-webkit-keyframes loader-2-1 {
        0%   { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes loader-2-1 {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loader-2 span {
        display: block;
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        margin: auto;
        height: 100px;
        width: 100px;
        clip: rect(16px, 32px, 32px, 0);
        -webkit-animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
                animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }
    @-webkit-keyframes loader-2-2 {
        0%   { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes loader-2-2 {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loader-2 span::before {
        content: "";
        display: block;
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        margin: auto;   
        border: 8px solid rgba(234,226,218,0.9);
        border-top: 8px solid rgba(212,201,190,0.9);
        border-radius: 50%;
        -webkit-animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
                animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }
    @-webkit-keyframes loader-2-3 {
        0%   { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes loader-2-3 {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loader-2 span::after {
        content: "";
        display: block;
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        margin: auto;
        height: 32px;
        width: 32px;
        border: 8px solid rgba(255, 255, 255, .5);
        border-radius: 50%;
    }
`

const LoaderCircle = () => {
    return (
        <Root>
            <div class="loader-2 center"><span></span></div>
        </Root>
    )
}

export default LoaderCircle;