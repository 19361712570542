import { useTranslation } from 'react-i18next'
import {
    ModalWrapper, Modal, ModalTop, SearchIcon, ModalTitle, Input
} from './styles'
import searchIcon from './../../../assets/search.svg';



const MasterSearch = (props) => {
    const { t } = useTranslation();

    return (
        <ModalWrapper>
            <Modal>
                <ModalTop>
                    <SearchIcon alt='search' src={searchIcon} />
                    <ModalTitle>
                        {t('searchMaster')}
                    </ModalTitle>
                </ModalTop>
                <Input onChange={(e) => {props.onChange(e.currentTarget.value)}}/>
            </Modal>
        </ModalWrapper>
    )
}

export default MasterSearch;