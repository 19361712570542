import React from 'react';
import SoldBlock from './CheckWorks/SoldBlock'

const AddWork = () => {
    return(
        <>
        <SoldBlock />
        </>
    );
}

export default AddWork;