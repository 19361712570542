import React from 'react'
import styled from 'styled-components/macro'
import CardBlock from './Favorite/CardBlock'
import { useTranslation } from 'react-i18next'
import heart from './../../assets/like.png'

const Block = styled.section` 
bottom: 20rem;
left: 34rem;
width: 790px;
@media (max-width: 577px){
  bottom: 8rem;
  width: 100%;
  height: fit-content;
  left: 0rem;
  margin: 0;
  box-sizing: border-box;
  padding: 0 5px;
}
`

const Head = styled.div`
font-weight: 400;
font-size: 26px;
display: flex;
padding-bottom: 10px;
border-bottom: 1px solid rgba(148, 148, 148, 0.5);
font-family: 'Palatino';

@media (max-width: 577px){
  margin-top: 110px;
  padding: 0 5px;
}
img{
    width: 30px;
    height: 30px;
    position: relative;
    opacity: 0.8;
    top: 2px;
    left: 10px;
}
`

const Favorite = () => {

  const { t } = useTranslation()

  return (
    <Block>
    <Head>
        {t('Likes')}<img src={heart} alt='heart' />
    </Head>
    <CardBlock />
    </Block>
  )
}

export default Favorite