import styled from 'styled-components/macro';

export const Root = styled.div`
    box-sizing: border-box;
    padding: 45px 250px 250px;
    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: 1024px){
        padding: 40px 111px;
    }
    
    @media (max-width: 414px){
        padding: 30px 20px;
    }
`

export const TitlePage = styled.div`
    padding-bottom: 8px;
    font-size: 32px;
    font-weight: 400;
    font-family: Palatino Linotype;
    color: rgba(0,0,0,0.80);
    opacity: 0.8;

    @media (max-width: 1024px){
        padding-bottom: 5px;
        font-size: 30px;
    }

    @media (max-width: 414px){
        padding-bottom: 5px;
        font-size: 25px;
    }
`


