import styled from 'styled-components/macro'
import closeSVG from './../MessageModal/close.svg';
import { useTranslation } from 'react-i18next'

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.7);
`
const Modal = styled.div`
    background: #FFFFFF;
    padding: 30px 0 40px;
    border-radius: 10px;
    width: 600px;

    @media (max-width: 577px){
        width: 362px;
        padding: 14px 0;
    }
`

const ModalTopWrapper = styled.div`
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5);

    @media (max-width: 577px){
        padding-bottom: 0;
    }
`
const ModalTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    padding: 0 45px;

    @media (max-width: 577px){
        padding: 0 17px 16px;
    }
`

const ModalCloseBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`

const ModalTitle = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;

    @media (max-width: 577px){
        font-size: 13px;
    }
`

const ModalMain = styled.div`
   padding: 20px 45px 0;
   margin-bottom: 10px;

    img {
        width: 100%;
    }

   @media (max-width: 577px){
        padding: 26px 17px 0;
    }
`

const AttachmentModal = ({onClose, attachment}) => {
    const { t } = useTranslation();

    return (
        <ModalWrapper>
            <Modal>
                <ModalTopWrapper>
                    <ModalTop>
                        <ModalTitle>
                            {t('viewAttachment')}
                        </ModalTitle>
                        <ModalCloseBtn onClick={onClose}>
                            <img alt='close' src={closeSVG} />
                        </ModalCloseBtn>
                    </ModalTop>
                </ModalTopWrapper>
                <ModalMain>
                    <img src={`https://toobears.com/api${attachment}`} alt='attacment'/>
                </ModalMain>
            </Modal>
        </ModalWrapper>
    )
}

export default AttachmentModal;