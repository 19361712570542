import { useTranslation } from 'react-i18next'
import {Root, TitlePage
} from './styles';
import BlockTemplate from '../../ui-kit/block-template/block-template';
import Wiki_TeddyBears_avatar from './imgs/Wiki_TeddyBears.png';
import Ilka_Bukinist_avatar from './imgs/Ilka_Bukinist.png';

const Ilka_Bukinist_href = 'https://t.me/ilka_bukinist';
const Wiki_TeddyBears__href = 'http://www.medvedted.ru';
const Partners = () => {
    const { t } = useTranslation();

    const Ilka_Bukinist_DESC = [
        t('Ilka_Bukinist_Desc1'), 
        t('Ilka_Bukinist_Desc2'), 
        t('Ilka_Bukinist_Desc3'), 
        t('Ilka_Bukinist_Desc4'), 
        t('Ilka_Bukinist_Desc5'), 
        t('Ilka_Bukinist_Desc6')
    ]

    return (
        <Root>
            <TitlePage>{t('OurPartners')}</TitlePage>
            <BlockTemplate 
                title='Wiki_TeddyBears' 
                texts={[t('Wiki_TeddyBears_Desc')]} 
                img={Wiki_TeddyBears_avatar} 
                button={t('GoToWeb')}
                href={Wiki_TeddyBears__href}
            />
            <BlockTemplate 
                title='Ilka_Bukinist'  
                texts={Ilka_Bukinist_DESC} 
                img={Ilka_Bukinist_avatar} 
                button={t('GoToChanel')}
                href={Ilka_Bukinist_href}
            />
        </Root>
    )
}

export default Partners;