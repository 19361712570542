import {ToastWrapper, ToastTop, ToastCloseImg, ToastMain, ToastNotificationImg, ToastInfo} from './styles';
import closeIcon from './img/close.svg';
import notificationIcon from './img/notification.svg';
import { useState } from 'react';
import { useEffect } from 'react';

const Toast = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => (
        setIsOpen(props.isOpenNotification)
    ), [props.isOpenNotification])

    return (
        <>
            {isOpen &&
                <ToastWrapper marginTop={props.marginTop} isRed={props.isRed}>
                    <ToastTop>
                        <ToastCloseImg withoutClose={props.withoutClose} onClick={() => setIsOpen(false)} alt='close' src={closeIcon}/>
                    </ToastTop>
                    <ToastMain>
                        <ToastNotificationImg alt='close' src={notificationIcon}/>
                        <ToastInfo>{props.description}</ToastInfo>
                    </ToastMain>
                </ToastWrapper>
            }
        </>
    )
}

export default Toast;