import { useTranslation } from 'react-i18next'
import {
    Root, SectionMenu, ContainerMenu, Options, 
    SectionMasters, Filters, SearchContainer, SearchText, ArrowImg
} from './styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from './../../utils/axios'
import MastersCard from './master-card/master-card';
import arrow from './master-card/assets/down-arrow.svg'
import MasterSearch from './master-modal-search/master-search';
import debounce from 'lodash.debounce';

const MastersPage = () => {
    const { t } = useTranslation();
    const [masters, setMasters] = useState();
    const [searchTerm, setSearchTerm] = useState('')
    const [isOpenSearch, setIsOpenSearch] = useState(false);

    const getMasters = useCallback(async (search) => {
        const { data } = await axios.get(`/masters`, { params: { q: search } });

        setMasters(data);
    }, []);

    const debouncedFetch = useMemo(() => debounce(() => getMasters(searchTerm), 500), [getMasters, searchTerm])

    useEffect(() => {
        debouncedFetch()
    }, [debouncedFetch])

    useEffect(() => {
        return () => {
          debouncedFetch.cancel();
        }
    }, [debouncedFetch]);

    useEffect(() => {
        getMasters()
    }, [getMasters]);

    return (
        <Root>
            <SectionMenu>
                <ContainerMenu>
                    <Options>
                        {t('masters')}
                    </Options>
                    <Filters>
                        <SearchContainer onClick={() => setIsOpenSearch(!isOpenSearch)}>
                            <SearchText>
                                {t('search')}
                            </SearchText>
                            <ArrowImg alt='arrow' src={arrow}/>
                        </SearchContainer>
                        {isOpenSearch &&
                            <MasterSearch onChange={setSearchTerm}/>
                        }
                    </Filters>
                </ContainerMenu>
            </SectionMenu>
            <SectionMasters>
                {masters?.map((master) => (
                    <MastersCard
                        key={master._id}
                        avatar={master.avatarUrl}
                        shopName={master.shopname}
                        name={`${master.username} ${master.surname}`}
                        country={master.country}
                        link={master.shortlink || master._id}
                        subs={master.subsCount}
                        workItemFirst={master.cards[0]?.imgUrl[0]}
                        workItemSecond={master.cards[1]?.imgUrl[0]}
                        numberWorks={master.cards.length}
                    />
                ))}
            </SectionMasters>
        </Root>
    )
}

export default MastersPage;