import styled from 'styled-components/macro';
import banner from './assets/banner-show.png';
import bannerMobile from './assets/banner-show-mobile.png';
import starTop from './assets/StarTop.svg';
import starBot from './assets/StarBot.svg';
import starTopTable from './assets/StarTopTable.svg';
import starBotTable from './assets/StarBotTable.svg';
import starTopMoobile from './assets/StarTopMobile.svg';
import starBotMobile from './assets/StarBotMobile.svg'


export const Root = styled.div`
    box-sizing: border-box;
    padding: 0 180px;
    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: 1024px){
        padding: 0 111px;
    }

    @media (max-width: 577px){
        padding: 0 40px;
    }
    
    @media (max-width: 414px){
        padding: 0 20px;
    }
`

export const BannerBear = styled.div`
    background: url(${banner}) no-repeat;
    width: 100%;
    height: 600px;
    background-position: center;
    background-size: contain;
    margin-bottom: 9px;
    margin-left: -35px;


    @media (max-width: 1024px){
        background: url(${banner}) no-repeat;
        background-position: center;
        background-size: contain;
        height: 340px;
        margin-bottom: 20px;
        margin-left: 0;
    }
    
    @media (max-width: 517px){
        background: url(${bannerMobile}) no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: -70px;
        margin-bottom: 0;
    }
`

export const OnlineShowTitle = styled.div`
  font-family: "AcontiumNapellus";
  line-height: 60px;
  font-size: 48px;
  text-align: center;
  color: #EE2152;
  margin-bottom: 40px;
  margin-top: -50px;

  @media (max-width: 1024px){
        line-height: 40px;
        font-size: 38px;
        margin-bottom: 23px;
        margin-top: 0;
    }
    
    @media (max-width: 414px){
        line-height: 28px;
        font-size: 24px;
        margin-top: -15px;
    }
`

export const OnlineShowSubTitle = styled(OnlineShowTitle)`
    color: rgba(111, 197, 255, 1);   
    margin-bottom: 42px;

    @media (max-width: 414px){
        margin-bottom: 10px;
        margin-top: 0;
    }
`

export const MainText = styled.div`
    font-family: Palatino Linotype;
    opacity: 0.8;
    font-size: 30px;
    font-weight: 400;
    line-height: 35px; 
    margin-bottom: 34px;

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
        margin-bottom: 24px;
    }
    
    @media (max-width: 414px){
        font-size: 20px;
        line-height: 20px; 
        margin-bottom: 30px;
    }
`

export const MainTextTitle = styled.span`
    color:rgba(111, 197, 255, 1);
    font-family: Palatino Linotype;
    font-size: 35px;
    font-weight: 700;
    line-height: 35px;

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
    }
    
    @media (max-width: 414px){
        font-size: 20px;
        line-height: 20px; 
    }
`

export const ShowListTitle = styled.div`
    color: rgba(111, 197, 255, 1);
    font-family: Palatino Linotype;
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
    opacity: 0.8;

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
    }
    
    @media (max-width: 414px){
        font-size: 20px;
        line-height: 20px; 
    }
`


export const ShowListTitleBeige= styled(ShowListTitle)`
    color: #D68462;
`

export const ShowListItemGreen = styled.span`
    color: rgba(233, 71, 81, 1);
`

export const ParticipantListTitle = styled(ShowListTitle)`
    text-align: center;
    width: 100%;
    color: #6FC5FF;
    opacity: 0.8;
    font-size: 36px;

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
    }
    
    @media (max-width: 414px){
        font-size: 20px;
        line-height: 20px; 
    }
`

export const ShowList = styled.ul`
    list-style-type: decimal;
    margin-bottom: 45px;
    padding-inline-start: 27px;

    @media (max-width: 1024px){
        margin-bottom: 20px;
    }

    @media (max-width: 414px){
        padding-inline-start: 20px;
    }
`

export const ShowListSmall = styled.ul`
    list-style-type: disc;
    padding-inline-start: 40px;

    @media (max-width: 414px){
        padding-inline-start: 15px;
    }
`

export const ParticipantListSmall = styled.ol`
    padding-inline-start: 40px;
    list-style-type: decimal;

    @media (max-width: 460px){
        padding-inline-start: 30px;
    }

    @media (max-width: 414px){
        padding-inline-start: 21px;
    }
`

export const ParticipantListWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1160px){
        display: none;
    }
`

export const ParticipantListWrapperMobile = styled(ParticipantListWrapper)`
    display: none;

    @media (max-width: 1160px){
        display: flex;
    }
`

export const ShowListItem = styled.li`
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    opacity: 0.8; 

    @media (max-width: 1024px){
        font-size: 18px;
        line-height:25px; 
    }
    
    @media (max-width: 414px){
        font-size: 15px;
        line-height: 22px; 
    }
`

export const ShowListItemNotPoint = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    opacity: 0.8; 

    @media (max-width: 1024px){
        font-size: 18px;
        line-height:25px; 
    }
    
    @media (max-width: 414px){
        font-size: 15px;
        line-height: 22px; 
    }
`

export const ShowListBenefitItem = styled(ShowListItem)`
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 13px;

    @media (max-width: 1500px){
        font-size: 20px;
        line-height: 26px;
        font-weight: 400; 
    }

    @media (max-width: 1220px){
        font-size: 18px;
        font-size: 18px;
        line-height:25px;
        margin-bottom: 0;
    }
    
    @media (max-width: 414px){
        font-size: 15px;
        line-height: 22px; 
        font-weight: 400; 
    }
`

export const ParticipantItem = styled(ShowListBenefitItem)`
    @media (max-width: 470px){
        font-size: 11px;
        line-height: 16px; 
    }
`

export const ShowByeTitle= styled(ShowListTitle)`
    text-align: center;
    margin-bottom: 150px;
    color: #E94751;
    font-style: italic;
    opacity: 1;
`

export const OurBenefits = styled.div`
    padding: 27px 40px;
    border-radius: 30px;
    border: 1px solid rgba(171, 154, 144, 0.50); 
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 35px;
    margin-top: 90px;

    &:after {
        content: '';
        position: absolute;
        display: block;
        background: url(${starTop});
        width: 63px;
        height: 54px;
        right: 138px;
        top: -27px;
	}

    &:before {
        content: '';
        position: absolute;
        display: block;
        background: url(${starBot});
        width: 48px;
        height: 41px;
        left: 30px;
        bottom: -22px;
	}

    @media (max-width: 1024px){
        padding: 21px 44px;
        margin-bottom: 40px;
        margin-top: 60px;

        &:after {
            background: url(${starTopTable});
            width: 48px;
            height: 41px;
            right: 85px;
            top: -21px;
	    }

        &:before {
            background: url(${starBotTable});
            width: 30px;
            height: 25px;
            left: 67px;
            bottom: -13px;
        }
    }
    
    @media (max-width: 414px){
        padding: 30px;
        margin-bottom: 37px;
        margin-top: 33px;

        &:after {
            background: url(${starTopMoobile});
            width: 31px;
            height: 26px;
            right: 47px;
            top: -13px;
	    }

        &:before {
            background: url(${starBotMobile});
            width: 30px;
            height: 25px;
            left: 27px;
            bottom: -13px;
        }
    }
`

export const OurParticipatns = styled(OurBenefits)`
    padding: 27px 70px 60px;
    margin-bottom: 70px;
    margin-top: 70px;
    height: 100%;

    &:after {
        content: '';
        position: absolute;
        display: block;
        background: url(${starTop});
        width: 63px;
        height: 54px;
        left: 110px;
        top: -27px;
	}

    &:before {
        content: '';
        position: absolute;
        display: block;
        background: url(${starBot});
        width: 48px;
        height: 41px;
        left: auto;
        right: 100px;
        bottom: -22px;
	}
    
    @media (max-width: 1024px){
        padding: 21px 20px;
        margin-bottom: 40px;

        &:after {
            background: url(${starTopTable});
            width: 48px;
            height: 41px;
            right: 85px;
            top: -21px;
	    }

        &:before {
            background: url(${starBotTable});
            width: 30px;
            height: 25px;
            bottom: -13px;
            left: auto;
            right: 50px;
        }
    }

    @media (max-width: 414px){
        padding: 20px;
        margin-top: 50px;
        margin-bottom: 27px;

        &:after {
            background: url(${starTopMoobile});
            width: 31px;
            height: 26px;
            left: 40px;
            right: auto;
            top: -13px;
	    }

        &:before {
            background: url(${starBotMobile});
            width: 30px;
            height: 25px;
            left: auto;
            right: 30px;
            bottom: -13px;
        }
    }
`

export const OurJury = styled.div`
    margin: 70px 0 60px;


    @media (max-width: 1024px){
        margin: 0 0 78px;
    }

    @media (max-width: 414px){
        margin: 0 0 48px;
    }
`

export const OurJuryWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 80px;

    @media (max-width: 414px){
        margin-top: 35px;
    }
`

export const OurJuryItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    margin-right: 190px;

    :last-child {
        margin-right: 0;
    }

    @media (max-width: 1024px){
        margin-right: 40px;
    }

    @media (max-width: 577px){
        width: 170px;
    }

    @media (max-width: 414px){
        margin-right: 30px;
    }
`

export const JuryAvatarWrapper = styled.div`
    position :relative;
`

export const JuryAvatarMaskRight = styled.img`
    display: block;
    width: 300px;
    position: absolute;
    top: -46px;
    left: -53px;

    @media (max-width: 640px){
        width: 191px;
        top: -30px;
        left: -33px;
    }

    @media (max-width: 414px){
        width: 156px;
        top: -21px;
        left: -26px;
    }
`

export const JuryAvatarMaskLeft = styled(JuryAvatarMaskRight)`
    top: -50px;
    left: -53px;

    @media (max-width: 640px){
        top: -33px;
        left: -33px;
    }

    @media (max-width: 414px){
        width: 150px;
        top: -21px;
        left: -23px;
    }
`

export const JuryAvatar = styled.img`
    display: block;
    width: 180px;
    margin-bottom: 15px;

    @media (max-width: 640px){
        width: 114px;
    }

    @media (max-width: 414px){
        width: 98px;
        margin-bottom: 5px;
    }
`

export const JuryCircle = styled.img`
    display: block;
    width: 194px;
    margin-bottom: 15px;
    position: absolute;
    top: -11px;
    left: -8px;

    @media (max-width: 640px){
        width: 125px;
        top: -8px;
        left: -6px;
    }

    @media (max-width: 414px){
        width: 106px;
        top: -6px;
        left: -4px;
    }
`

export const JuryCircleRight = styled(JuryCircle)`
    width: 200px;
    top: -8px;
    left: -11px;

    @media (max-width: 640px){
        width: 128px;
        top: -5px;
        left: -7px;
    }

    @media (max-width: 414px){
        width: 110px;
        top: -5px;
        left: -5px;
    }
`

export const JuryName = styled.div`
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    font-family: Palatino Linotype;
    opacity: 0.7;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 60px;

    @media (max-width: 577px){
        font-size: 20px; 
        margin-top: 30px;
    }

    @media (max-width: 414px){
        font-size: 15px; 
        line-height: 15px; 
        margin-top: 25px;
    }
`

export const JuryNameFirst = styled(JuryName)`
    margin-top: 70px;

    @media (max-width: 577px){
        margin-top: 30px;
    }

    @media (max-width: 414px){
        margin-top: 25px;
    }
`

export const JuryCountry = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`

export const JuryCountryMark = styled.img`
    display: block;
    width: 15px;

    @media (max-width: 414px){
        width: 9px;
    }
`

export const JuryCountryName = styled.div`
    font-size: 20px;
    opacity: 0.8;

    @media (max-width: 414px){
        font-size: 11px; 
    }
`

export const JuryDesc = styled.div`
    font-size: 20px;
    opacity: 0.8; 

    @media (max-width: 414px){
        font-size: 10px; 
    }
`

export const Remark = styled.div`
    font-size: 30px;
    line-height: 44px; 
    opacity: 0.8;
    margin-bottom: 60px;

    @media (max-width: 1024px){
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 40px;
    }

    @media (max-width: 414px){
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
    }
`

export const GreetingTitle = styled.div`
    color: rgba(238, 33, 82, 0.8);
    font-family: "AcontiumNapellus";
    font-size: 40px;
    font-weight: 400;
    line-height: 42px;
    text-align: center;
    margin-bottom: 65px;

    @media (max-width: 1024px){
        font-size: 30px;
        line-height: 38px; 
        margin-bottom: 20px;
    }

    @media (max-width: 414px){
        display: none;
    }
`

export const WinnersTitle = styled(GreetingTitle)`
    margin: 70px 0;
    font-family: Palatino Linotype;
    font-weight: 700;   

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
    }
    
    @media (max-width: 414px){
        display: none;
    }
`

export const WinnersTitleMobile = styled(GreetingTitle)`
    display: none;
    
    @media (max-width: 414px){
        color: rgba(155, 78, 227, 0.69);
        display: block;
        font-size: 20px;
        line-height: 20px; 
        margin: 25px 0 0;
        font-family: Palatino Linotype;
        font-style: italic;
        font-weight: 700;  
    }
`

export const WinnersIcon = styled.img`
    width: 30px;

    @media (max-width: 414px){
        width: 23px;
    }
`

export const GreetingTitleMobile = styled(GreetingTitle)`
    display: none;

    @media (max-width: 414px){
        display: block;
        font-size: 20px;
        line-height: 34px; 
        margin-bottom: 10px;
        line-height: 30px; 
        margin-bottom: 20px;
    }
`

export const TestGradText = styled.span`
    background: -webkit-linear-gradient(rgba(238, 33, 82, 0.79)  34%, rgba(155, 78, 227, 0.69) 83%);
    font-style: italic;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;

    @media (max-width: 414px){
        background: -webkit-linear-gradient(rgba(238, 33, 82, 0.79), rgba(155, 78, 227, 0.69) 107%);
        line-height: 40px;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
    }
`
export const TestGradTextSecond = styled.span`
    background: -webkit-linear-gradient(rgba(238, 33, 82, 0.79)  34%, rgba(155, 78, 227, 0.69) 61%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-style: italic;

    @media (max-width: 414px){
        background: -webkit-linear-gradient(rgba(155, 78, 227, 0.8) 100%, rgba(155, 78, 227, 0.8) 100%);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
    }
`

export const WinnerText = styled.div`
    font-family: Palatino Linotype;
    font-size: 30px;
    line-height: 35px; 
    opacity: 0.8;
    margin-top: 65px;
    margin-bottom: 170px;

    @media (max-width: 1024px){

    }

    @media (max-width: 414px){
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 20px;
    }
`

export const ButtonSend = styled.button`
   border: none;
   cursor: pointer;
   padding: 0 55px;
   width: 283px;
   text-align: center;
   height: 58px;
   background: 	rgba(254,97,3,0.15);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);       
   border-radius: 10px;   
   font-size: 20px;  
   font-family: Palatino Linotype;
   margin: 0 auto;
   display: block;
   opacity: 0.8;
   margin-bottom: 100px;
   color: #000000;
   
   @media (max-width: 577px){
        font-size: 12px;  
        padding: 0 35px;
        width: 165px;
        height: 30px;
    }
`