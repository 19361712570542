import styled from 'styled-components/macro';

export const Input = styled.input`
    border:  ${props => props.error ? '1px solid #FB0808' : '1px solid #949494;'} !important; 
    height: 35px;
    border-radius: 10px;
    outline: none;
    text-indent: 5px;
`

export const ErrorText = styled.div`
    color: #FB0808;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin-top: 5px;   
     
    @media (max-width: 414px){
        margin-left: ${props => props.marginLeft ? '1.2rem' : '0'};
    }
`

export const Textarea = styled.textarea`
    border:  ${props => props.error ? '1px solid #FB0808' : '1px solid #949494;'} !important;
    font-family: 'Montserrat';
    border-radius: 10px;
    outline: none;
    resize: none;
    margin-top: 7px;
    width: 100%;
    height: 130px;
    padding: 10px;
    font-size: 14px;
`