import { useTranslation } from 'react-i18next'
import {Root, Title, Description, SubTitle} from './styles';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <Title>{t('privacyPolicy')}</Title>
            <Description class='marginBottom'>
                {t('PPContent')}
            </Description>
            <SubTitle>{t('PPSubTitle1')}</SubTitle>
            <Description>
                {t('PPDesc1')}
                <br/>
                <br/>
                {t('PPDesc2')}
                <br/>
                <br/>
                {t('PPDesc3')}
                <br/>
                <br/>
                {t('PPDesc4')}
            </Description>
            <SubTitle>{t('PPSubTitle2')}</SubTitle>
            <Description>
                {t('PPDesc5')}
                <br/>
                <br/>
                {t('PPDesc6')}
            </Description>
            <SubTitle>{t('PPSubTitle3')}</SubTitle>
            <Description>
                {t('PPDesc7')}
            </Description>
            <SubTitle>{t('PPSubTitle4')}</SubTitle>
            <Description>
                {t('PPDesc8')}
            </Description>
            <SubTitle>{t('PPSubTitle5')}</SubTitle>
            <Description>
                {t('PPDesc10')}
            </Description>
            <SubTitle>{t('PPSubTitle6')}</SubTitle>
            <Description>
                {t('PPDesc11')}
            </Description>
            <SubTitle>{t('PPSubTitle7')}</SubTitle>
            <Description>
                {t('PPDesc12')}
            </Description>
            <SubTitle>{t('PPSubTitle8')}</SubTitle>
            <Description>
                {t('PPDesc13')}
            </Description>
            <SubTitle>{t('PPSubTitle9')}</SubTitle>
            <Description>
                {t('PPDesc14')}
            </Description>
            <SubTitle>{t('PPSubTitle10')}</SubTitle>
            <Description>
                {t('PPDesc15')}
                <br/>
                <br/>
                {t('PPDesc16')}
            </Description>
        </Root>
    )
}

export default PrivacyPolicy;