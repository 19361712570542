import styled from 'styled-components/macro'
import closeSVG from './../MessageModal/close.svg';
import { useState } from 'react';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
`
const Modal = styled.div`
    width: 500px;
    background: #FFFFFF;
    padding: 30px 0 40px;
    border-radius: 10px;

    @media (max-width: 577px){
        width: 362px;
        padding: 14px 0;
    }
`

const ModalTopWrapper = styled.div`
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5);

    @media (max-width: 577px){
        padding-bottom: 0;
    }
`
const ModalTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    padding: 0 45px;

    @media (max-width: 577px){
        padding: 0 17px 16px;
    }
`

const ModalCloseBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`

const ModalTitle = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000000;

    @media (max-width: 577px){
        font-size: 13px;
    }
`

const ModalMain = styled.div`
   padding: 20px 45px 0;
   margin-bottom: 10px;

   @media (max-width: 577px){
        padding: 26px 17px 0;
    }
`
const ButtonSend = styled.button`
   border: none;
   cursor: pointer;
   padding: 10px;
   text-align: center;
   height: 40px;
   width: 120px;
   background: 	rgba(254,97,3,0.15);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);       
   border-radius: 10px;   
   font-size: 15px;  
   
   @media (max-width: 577px){
        padding: 0;
        height: 25px;
        width: 100px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 45px;
`

const LabelText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;

    @media (max-width: 577px){
        font-size: 12px;
    }
`

const Input = styled.input`
    padding: 5px 10px;
    width: 350px;
    height: 35px;
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    border-radius: 5px;
    outline: none;

    @media (max-width: 577px){
        font-size: 12px;
        width: 280px;
        height: 20px;
    }
`

const ResetPassModal = ({onClose}) => {
    const [email, setEmail] = useState('')
    const { t } = useTranslation();
    const navigate = useNavigate();

    const changeEmail = (e) => {
        setEmail(e.target.value)
    }

    const sendEmail = async (email) => {
        await axios.post('/auth/forgot', {email});
        navigate('/');
        alert(t('resetPassMessage'))
    }

    return (
        <ModalWrapper>
            <Modal>
                <ModalTopWrapper>
                    <ModalTop>
                        <ModalTitle>
                            {t('resetPass')}
                        </ModalTitle>
                        <ModalCloseBtn onClick={onClose}>
                            <img alt='close' src={closeSVG} />
                        </ModalCloseBtn>
                    </ModalTop>
                </ModalTopWrapper>
                <ModalMain>
                    <label>
                        <LabelText>
                            {t('writeYourMail')}
                        </LabelText>
                        <Input value={email} type='email' onInput={changeEmail}/>
                    </label>
                </ModalMain>
                <ButtonWrapper>
                    <ButtonSend onClick={() => sendEmail(email)}>{t('send')}</ButtonSend>
                </ButtonWrapper>
            </Modal>
        </ModalWrapper>
    )
}

export default ResetPassModal;