import {Link, useMatch} from 'react-router-dom';

const CustomLink = ({children, to, ...props}) => {
    const match = useMatch(to)

    return (

        <Link
        to={to}
        style={{
            backgroundColor: match ? 'rgba(213, 200, 200, 0.6)' : '#F5F5F5'
        }}
        {...props}
        >
        {children}
        </Link>

    )
}

export {CustomLink}