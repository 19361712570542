import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const fetchCards = createAsyncThunk('/market/fetchCards', async () => {
    const { data } = await axios.get('/market');
    return data;
})

export const fetchAddCards = createAsyncThunk('/market/fetchAddCards', async (params) => {
    const { data } = await axios.post('/market', params);
    return data;
})

export const fetchOneCard = createAsyncThunk('/market/fetchOneCard', async () => {
    const { data } = await axios.get('/market/:id', );
    return data;
})


const initialState = {
    cards: {
        items: [],
        status: 'loading',
        scrollPosition: null,
        cathegory: 'teddy',
        currentPage: 1
    },
}

const cardsSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        saveScrollPosition: (state, action) => {
            state.cards.scrollPosition = action.payload;
        },
        setCathegory: (state, action) => {
            state.cards.cathegory = action.payload
        },
        setCurrentPage: (state, action) => {
            state.cards.currentPage = action.payload
        }
    },
    extraReducers: {
        [fetchCards.pending] : (state) => {
            state.cards.status = 'loading'
        },

        [fetchCards.fulfilled] : (state, action) => {
            state.cards.items = action.payload
            state.cards.status = 'loaded'
        },

        [fetchCards.rejected] : (state) => {
            state.cards.items = []
            state.cards.status = 'error'
        },

        [fetchAddCards.pending] : (state) => {
            state.cards.status = 'loading'
        },

        [fetchAddCards.fulfilled] : (state, action) => {
            state.cards.items = action.payload
            state.cards.status = 'loaded'
        },

        [fetchAddCards.rejected] : (state) => {
            state.cards.items = []
            state.cards.status = 'error'
        },
        [fetchOneCard.pending] : (state) => {
            state.cards.status = 'loading'
        },

        [fetchOneCard.fulfilled] : (state, action) => {
            state.cards.items = action.payload
            state.cards.status = 'loaded'
        },

        [fetchOneCard.rejected] : (state) => {
            state.cards.items = []
            state.cards.status = 'error'
        },
    },
})

export const cardsSelect = (state) => state.cards.cards

export const cardsReducer = cardsSlice.reducer;

export const { saveScrollPosition, setCathegory, setCurrentPage } =  cardsSlice.actions
