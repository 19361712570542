import { useTranslation } from 'react-i18next'
import {Root, TitlePage
} from './styles';
import BlockTemplate from '../../ui-kit/block-template/block-template';

const News = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <TitlePage>{t('News')}</TitlePage>
            <BlockTemplate title={t('ChristmasAnnouncements')} texts={[t('ChristmasAnnouncementsText')]} date='17.09.2024'/>
            <BlockTemplate title={t('ScaryPartyEnd')} texts={[t('ScaryPartyEndText')]} date='13.09.2024'/>
            <BlockTemplate title={t('ScaryPartyStart')} texts={[t('ScaryPartyStartText')]} date='01.07.2024'/>
            <BlockTemplate title={t('FairyTailEnd')} texts={[t('FairyTailEndText')]} date='18.06.2024'/>
            <BlockTemplate title={t('FairyTailStart')} texts={[t('FairyTailStartText')]} date='05.03.2024'/>
            <BlockTemplate title={t('SpringEnd')} texts={[t('SpringEndText')]} date='24.02.2024'/>
            <BlockTemplate title={t('OnlineShowTooBears')} texts={[t('News1')]} date='24.11.2023'/>
        </Root>
    )
}

export default News;