import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import ButtonSave from './Settings/ButtonSave'
import FirstInfo from './Settings/FirstInfo'
import Notify from './Settings/Notify'
import Password from './Settings/Password'
import SecondInfo from './Settings/SecondInfo'
import { fetchUpdateMe, selectIsAuth } from '../../redux/slices/auth'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

const Block = styled.section`
position: relative;
width: 790px;

@media (max-width: 577px){
  left: 0;
  bottom: 0;
  width: 100%;
  height: fit-content;
}
`

const Btn = styled.button`
position: relative;
width: 120px;
height: 30px;
background-color: #f1f1f1;
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 400;
font-size: 12px;
border-radius: 10px;
cursor: pointer;
left: 22rem;
top: 0rem;

`
const InfoBlock = styled.div`
margin-top: 3rem;
`

const Settings = () => {

  const dispatch = useDispatch()

  // const {register, handleSubmit, formState : {errors, isValid}} = useForm({
  //     defaultValues:{
  //       surname:'',
  //       username:'',
  //     },
  //     mode: 'onChange'
  //   })

  // const onSubmit = async (values) =>{
  //     const data = await dispatch(fetchUpdateMe(values))
  
  //     if (!data.payload) {
  //       return alert('Не удалось авторизоваться')
  //     }
  
  //     window.location.reload();
  // }  

  return (
    <Block>
        {/* <InfoBlock> */}
        <FirstInfo />
        {/* <SecondInfo /> */}
        {/* <Notify />
        <Password /> */}
        {/* </InfoBlock> */}
        {/* <ButtonSave /> */}
    </Block>
  )
}

export default Settings