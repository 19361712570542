import { useTranslation } from 'react-i18next'
import {OurBenefits, ShowListTitleBeige, ShowListSmall , ShowListBenefitItem} from './../../styles';

const Benefits = () => {
    const { t } = useTranslation();

    return (
        <OurBenefits>
            <ShowListTitleBeige><i>{t('OurBenefits')}</i></ShowListTitleBeige>
            <ShowListSmall>
                <ShowListBenefitItem>{t('OurBenefitsItem1')}</ShowListBenefitItem>
                <ShowListBenefitItem>{t('OurBenefitsItem2')}</ShowListBenefitItem>
                <ShowListBenefitItem>{t('OurBenefitsItem3')}</ShowListBenefitItem>
                <ShowListBenefitItem>{t('OurBenefitsItem4')}</ShowListBenefitItem>
                <ShowListBenefitItem>{t('OurBenefitsItem5')}</ShowListBenefitItem>
            </ShowListSmall>
        </OurBenefits>
    )
}

export default Benefits;