import styled from 'styled-components/macro';

// export const MarketPart = styled.div`
//   margin-top: 65px;
//   display: grid;
//   grid-template-columns: 23% 23% 23% 23%;
//   justify-content: space-between;
//   grid-gap: 30px;

//   @media (max-width: 577px) {
//     margin: 0;
//     margin-top: 25px;
//     padding-top: 26px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     & > a {
//       overflow: hidden;
//     }
//   }
// `

export const MarketPart = styled.div`
  margin-top: 65px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  //   display: flex;
  // flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 45px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1024px) {
    margin: 0;
    margin-top: 25px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > a {
      overflow: hidden;
    }
  }

  @media (max-width: 577px) {
    margin: 0;
    margin-top: 25px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;


    & > a {
      overflow: hidden;
    }
  }
`

export const PaginationWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`

export const PaginationBtnFirst = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  color: #808183;
  border: 1px solid rgba(145,114,114,0.2);
  cursor: pointer;
  background: transparent;
  border-radius: 10px 0 0 10px;
  opacity: 0.9;
`

export const PaginationBtnActive = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  color: #FFFFFF;
  border: 1px solid rgba(145,114,114,0.2);  
  cursor: pointer;
  background: #FE6103;
  opacity: 0.35;
  font-family: "Montserrat",sans-serif;
`

export const PaginationBtn = styled.button`
  width: 30px;
  height: 30px;
  color: #808183;
  border-bottom: 1px solid rgba(171, 154, 144, 0.3);  
  border-top: 1px solid rgba(171, 154, 144, 0.3);  
  border-right: 1px solid rgba(171, 154, 144, 0.3);  
  border-left: none;  
  cursor: pointer;
  background: transparent;
  opacity: 0.9;
  font-family: "Montserrat",sans-serif;
  display: block;
`

export const PaginationBtnLast = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  color: #808183;
  border-right: 1px solid rgba(145,114,114,0.2); 
  border-top: 1px solid rgba(145,114,114,0.2);
  border-bottom: 1px solid rgba(145,114,114,0.2);
  border-left: none;   
  cursor: pointer;
  background: transparent;
  border-radius: 0 10px 10px 0;
  opacity: 0.9;
`