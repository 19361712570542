import styled from 'styled-components/macro'
import LoaderCircle from '../../ui-kit/loader-circle/loader-circle';
import { useTranslation } from 'react-i18next';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.7);
`

const ModalText = styled.div`
    margin-top: 40px;
    color: rgba(234,226,218,0.9);
    font-size: 28px;
`

const LoaderModal = (props) => {
    const { t } = useTranslation();
    return (
        <ModalWrapper>
            <LoaderCircle/>
            <ModalText>{props.text || t('load')}</ModalText>
        </ModalWrapper>
    )
}

export default LoaderModal;