import axios from 'axios'

const instance = axios.create({
    baseURL: '/api'   
})

// 188.72.203.49

instance.interceptors.request.use((config) => {
    config.headers.Authorization = window.localStorage.getItem('token')

    return config
})

export default instance