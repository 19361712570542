import {Title, Text, Date, Border,Content, ImgContainer, Btn} from './styles';

const BlockTemplate = (props) => {

    return (
        <>
            <Border/>
            <Title>{props.title}</Title>
            <Content>
                {props.img && 
                    <ImgContainer>
                        <img alt='avatar' src={props.img}/>
                    </ImgContainer>
                }
                <div>
                    {props.texts.map((text) => (
                        <Text>{text}</Text>
                    ))}
                </div>
            </Content>
            {props.date && <Date>{props.date}</Date>}
            {props.button && props.href && 
                <Btn href={props.href} target='_blank'>{props.button}</Btn>
            }
        </>
    )
}

export default BlockTemplate;