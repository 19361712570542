import React from 'react'
import Password from './Settings/Password'
import Notify from './Settings/Notify'
import styled from 'styled-components/macro'
import FirstParams from './Settings/FirstParams'
import ButtonSave from './Settings/ButtonSave'


const Section = styled.section`
position: relative;
max-width: 790px;
margin-left: 25px;

@media (max-width: 577px){
  width: 100%;
  margin-top: 0rem;
  left: 0;
  overflow: hidden;
  height: fit-content;
  margin-left: 0;
}
`

const Settings = () => {
  return (
    <>
    <Section>
      <FirstParams />
    </Section>
    </>
  )
}

export default Settings