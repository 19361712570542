import React from 'react'
import SellerCard from './SellerCard'
import { Outlet, Routes, Route, useLocation } from 'react-router-dom';
import Homepage from '../../SellerLK/Homepage';
import HomepageRedact from '../../SellerLK/HomepageRedact';
import Orders from '../../SellerLK/Orders';
import CheckWork from '../../SellerLK/CheckWork';
import SettingsLayout from './SettingsLayout';
import Settings from '../../SellerLK/Settings';
import Addition from '../../SellerLK/Addition';
import {  useSelector } from 'react-redux'
import { selectIsAuth } from '../../../redux/slices/auth'
import ProfileMenu from '../../UserLK/ProfileMenu';
import Subs from '../../UserLK/Subs'
import SettingsCol from '../../UserLK/Settings'
import FavoriteCol from '../../UserLK/Favorite'
import Message from '../../UserLK/Message'
import OrdersCol from '../../UserLK/Orders'
import FavLayout from '../../SellerLK/FavLayout';
import FavCards from '../../SellerLK/Favorite/CardBlock'
import Subscribers from '../../SellerLK/Favorite/Subs'
import styled from 'styled-components/macro';
import Banner from '../../SellerLK/Banner';
import EditWork from '../../SellerLK/EditWork';
import MessageAuthor from '../../SellerLK/Message-author';
import OrderInfo from '../../order-info/order-info';
import OrderInfoSeller from '../../order-info/order-info-seller';
import Add from '../../SellerLK/AddWork/AddWork';
import LoaderModal from '../../loader-modal/loader-modal';

const ContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1200px;
  margin: auto;
  padding-bottom: 20px;
  margin-top: 100px;

  @media (max-width: 577px) {
    display: block;
    margin-top: 0;  
  }
`

const StatusWrapper = styled.div`
padding-top: ${props => props.pathname === '/account' ? '162px' : '10px'};
position: relative;
flex: 1 1 100%;

@media (max-width: 577px) {
    padding-top: 0;
    margin-top: 65px;
  }
`

const SellerLayout = () => {
  const { pathname } = useLocation();
  const isAuth = useSelector(selectIsAuth);

  if (!isAuth) {
    return (
      <LoaderModal/>
    )
  }

  return (
    <ContainerStyled>
      {isAuth.role === 'Seller' ?
        <>
          {pathname === '/account' && <Banner />}
          <SellerCard />
          <StatusWrapper pathname={pathname}>
            <main className="container">
              <Outlet />
            </main>
            <Routes>
              <Route path='orderInfo/:id' element={<OrderInfoSeller />} />
              <Route index element={<Homepage />} />
              <Route path="Orders" element={<Orders />} />
              <Route path="AddWork" element={<Add />} />
              <Route path="edit/:id" element={<EditWork />} />
              <Route path="CheckWork" element={<CheckWork />} />
              <Route path='Message' element={<MessageAuthor />} />
              <Route path="Favorite" element={<FavLayout />}>
                <Route index element={<FavCards />} />
                <Route path='subs' element={<Subscribers />} />
              </Route>
              <Route path="SettingsMenu" element={<SettingsLayout />}>
                <Route index element={<Settings />} />
                <Route path="Addition" element={<Addition />} />
              </Route>
              <Route path="Redact" element={<HomepageRedact />} />
            </Routes>
          </StatusWrapper>
        </> 
        : (
          <>
            <ProfileMenu />
            <Routes>
              <Route path='orderInfo/:id' element={<OrderInfo />} />
              <Route index element={<OrdersCol/>} />
              <Route path='Message' element={<Message />} />
              <Route path='Subs' element={<Subs />} />
              <Route path='Update' element={<SettingsCol />} />
              <Route path='Favorite' element={<FavoriteCol />} />
            </Routes>
          </>
        )}
    </ContainerStyled>
  )
}

export default SellerLayout