import { useTranslation } from 'react-i18next'
import {Root, Title, Description, SubTitle} from './styles';

const TermOfUse = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <Title>{t('regTerms2.1')}</Title>
            <SubTitle>{t('TUSubTitle1')}</SubTitle>
            <Description>
                {t('TUDesc1/1')}
                <br/>
                {t('TUDesc1/2')}
            </Description>
            <SubTitle>{t('TUSubTitle2')}</SubTitle>
            <Description>
                {t('TUDesc2/1')}
                <br/>
                {t('TUDesc2/2')}
            </Description>
            <SubTitle>{t('TUSubTitle3')}</SubTitle>
            <Description>
                {t('TUDesc3/1')}
                <br/>
                {t('TUDesc3/2')}
                <br/>
                {t('TUDesc3/3')}
            </Description>
            <SubTitle>{t('TUSubTitle4')}</SubTitle>
            <Description>
                {t('TUDesc4')}
                <br/>
                {t('TUDesc5')}
                <br/>
                {t('TUDesc6')}
            </Description>
            <SubTitle>{t('TUSubTitle5')}</SubTitle>
            <Description>
                {t('TUDesc7')}
                <br/>
                {t('TUDesc8')}
                <br/>
                {t('TUDesc9')}
                <br/>
                {t('TUDesc10')}
                <br/>
                <br/>
                {t('TUDesc11')}
            </Description>
            <SubTitle>{t('TUSubTitle6')}</SubTitle>
            <Description>
                {t('TUDesc12')}
            </Description>
            <SubTitle>{t('TUSubTitle7')}</SubTitle>
            <Description>
                {t('TUDesc13')}
                <br/>
                {t('TUDesc14')}
                <br/>
                {t('TUDesc15')}
                <br/>
                {t('TUDesc16')}
                <br/>
                {t('TUDesc17')}
                <br/>
                {t('TUDesc18')}
            </Description>
            <SubTitle>{t('TUSubTitle8')}</SubTitle>
            <Description>
                {t('TUDesc19')}
            </Description>
            <SubTitle>{t('TUSubTitle9')}</SubTitle>
            <Description>
                {t('TUDesc20')}
            </Description>
            <SubTitle>{t('TUSubTitle10')}</SubTitle>
            <Description>
                {t('TUDesc21')}
            </Description>
            <SubTitle>{t('TUSubTitle11')}</SubTitle>
            <Description>
                {t('TUDesc22')}<a href='#'>{t('TUDesc23')}</a>
                <br/>
                <br/>
                {t('TUDesc24')}
            </Description>
        </Root>
    )
}

export default TermOfUse;