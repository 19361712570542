import React, { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'

import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

const Section = styled.section`
margin-left: 40px;
margin-top: -40px;

@media (max-width: 577px){
  width: 100%;
  position: relative;
  margin-left: 0;
}
`

const BtnFavorite = styled.button`
  margin-top: 4rem;
  width: 175px;
  height: 40px;
  background: ${props => props.isActive ? 'rgba(213, 200, 200, 0.6)' : '#F5F5F5'};
  border: 1px solid #D5C8C8;
  border-radius: 10px;
  font-family: 'Palatino';
  font-size: 18px;
  padding: -0,5rem 1rem;
  cursor: pointer;
  span{
    font-size: 14px;
  }

  @media (max-width: 577px){
    margin-left: 1rem;
  }

  @media (max-width: 380px){
    max-width: 140px;
  }
`

const BtnSubs = styled.button`
  margin: 3rem 1rem;
  width: 175px;
  height: 40px;
  background: ${props => props.isActive ? 'rgba(213, 200, 200, 0.6)' : '#F5F5F5'};
  border: 1px solid #E1DFD2;
  border-radius: 10px;
  font-family: 'Palatino';
  font-size: 18px;
  padding: -0,5rem 1rem;
  cursor: pointer;
  span{
    font-size: 14px;
  }
  @media (max-width: 577px){
    margin-left: 0.5rem;
  }

  @media (max-width: 380px){
    max-width: 140px;
  }
`

const FavLayout = () => {
  const [isActive, setIsActive] = useState('favorite');
  const { t } = useTranslation()

  return (
    <>
      <Section>
          <Link  to='/account/Favorite'><BtnFavorite isActive={isActive === 'favorite'} onClick={() => setIsActive('favorite')}>{t('favorite')}</BtnFavorite></Link>
          <Link  to='subs'><BtnSubs isActive={isActive === 'subs'} onClick={() => setIsActive('subs')}> {t('subscribed')}</BtnSubs></Link>
          <main className='container'>
              <Outlet />
          </main>
      </Section>
    </>
  )
}

export default FavLayout;
