import React from 'react';
import styled from 'styled-components/macro';
import img from './success-order.png'
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat';
`;

const SuccessImg = styled.img`
    display: block;
    margin-bottom: 10px;

    @media (max-width: 577px){
        width: 245px;
    }
`;

const SuccessTitle = styled.div`
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 40px;

    @media (max-width: 577px){
        font-size: 20px;
        margin-bottom: 10px;
    }
`;

const SuccessText = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 90px;

    @media (max-width: 577px){
        font-size: 15px;
        margin-bottom: 24px;
    }
`;

const SuccessBtn = styled.button`
    border: none;
    background: #FE6103;
    opacity: 0.7;
    border-radius: 10px;
    color:  #F5F5F5;
    font-size: 25px;
    width: 300px;
    height: 55px;
    padding: 0 10px;
    cursor: pointer;

    @media (max-width: 577px){
        width: 245px;
        height: 45px;
        font-size: 17px;
    }
`

const SuccessOrder = ({card}) => {
    const navigate = useNavigate();

    const toAccount = () => {
        navigate('/account');
    }

    return (
        <Container>
            <SuccessImg src={img}/>
            <SuccessTitle>Thanks for your order!</SuccessTitle>
            <SuccessText>{card.author.username} {card.author.surname} ({card.author.shopname}) will be contacting you soon.</SuccessText>
            <SuccessBtn onClick={() => toAccount()}>
                Continue
            </SuccessBtn>
        </Container>
    )
};

export default SuccessOrder;