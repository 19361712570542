import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, MainTextTitle, OnlineShowTitle
} from '../styles';
import {MarketPart, PaginationWrapper, PaginationBtnFirst, PaginationBtnActive, PaginationBtn, PaginationBtnLast} from './styles';
import axios from '../../../utils/axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CardPreview from '../../Card/card-preview';
import defaultToy from './../../../assets/defaultToy.png'
import defaultAvatar from './../../../assets/cardPhoto.png'
import { useSelector } from 'react-redux';
import { selectIsAuth } from '../../../redux/slices/auth';
import { getPagination, DOTS } from '../../MainPage/get-pagination';
import LoaderCircle from '../../../ui-kit/loader-circle/loader-circle';


const OnlineShowWorks = () => {
    const { t } = useTranslation();
    const isAuth = useSelector(selectIsAuth)
    const [cards, setCards] = useState([])
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true);
    const sectionRef = useRef(null);

    const pagination = useMemo(() => getPagination({
        totalPageCount: pages,
        currentPage
      }), [currentPage, pages]);

    const changePage = async(nextPage) => {
        setCurrentPage(nextPage);
        sectionRef.current && window.scrollTo({ top: sectionRef.current.offsetTop, behavior: 'smooth' })
    }

    const fetchCards = useCallback(
        async () => {
            try {
                const { data } = await axios.get(`/market`, { params: {
                    limit: 100, 
                    showpage: true
                }})
                setCards(data.items)
                setLoading(false)
                setPages(data.pages)
            } catch (error) {
                console.log(error)
            }
        }, []
    )

    useEffect(() =>{
        fetchCards()
    }, [fetchCards])

    return (
        <Root>
            <BannerBear></BannerBear>
            <OnlineShowTitle>{t('OnlineShowTitle')}</OnlineShowTitle>
            <MainText>
                <MainTextTitle><i>{t('ToobearShow')}</i> - </MainTextTitle>
                {t('ToobearShowDesc')}
            </MainText>
            <MainText>{t('ShowDesc2')}</MainText>
            {loading ? 
                <LoaderCircle/> : 
                <>
                    <MarketPart ref={sectionRef}>
                        {cards?.map((obj) => {
                            return (  
                                <CardPreview 
                                    onlineShow
                                    key={obj._id}
                                    altWork={obj.name} 
                                    imageWork={obj.imgUrl ?`https://toobears.com/api/${obj.imgUrl[0]}` : defaultToy}
                                    avatar={`https://toobears.com/api/${obj.author.avatarUrl}` || defaultAvatar}
                                    shopName={obj.author.shopname || `${obj.author.username} ${obj.author.surname}`}
                                    nameWork={obj.name}
                                    price={obj.price + ' ' + obj.currency}
                                    size={obj.size + 'cm'}
                                    likeActive={obj.like.includes(isAuth && isAuth._id)}
                                    status={obj.status}  
                                    link={`/card/${obj._id}`}  
                                />
                        )})} 
                    </MarketPart>
                </>
            }
        </Root>
    )
}

export default OnlineShowWorks;