import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const fetchAuth = createAsyncThunk('auth/fetchAuth', async (params) => {
    const { data } = await axios.post('/auth/login', params);
    return data;
} )


export const fetchRegister = createAsyncThunk('auth/fetchRegister', async (params) => {
    const { data } = await axios.post('/auth/register', params);
    return data;
} )

export const fetchAuthMe = createAsyncThunk('auth/fetchAuthMe', async (params) => {
    const { data } = await axios.get('/auth/me', params);
    return data;
} )

export const fetchUpdateMe = createAsyncThunk('auth/fetchUpdateMe', async (params) => {
    const { data } = await axios.patch('/auth/me', params);
    return params;
})

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (params) => {
    const { data } = await axios.get('/users', params)
    return data;
})

export const fetchOneUser = createAsyncThunk('users/fetchUsers', async (params) => {
    const { data } = await axios.get('/users/:id', params)
    return data;
})

const initialState = {
    data: null,
    status: 'loading'
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.data = null;
            state.status = null;
        }
    },
    extraReducers: {
        [fetchAuth.pending] : (state) => {
            state.status = 'loading'
            state.data = null
        },

        [fetchAuth.fulfilled] : (state, action) => {
            state.status = 'loaded'
            state.data = action.payload
        },

        [fetchAuth.rejected] : (state) => {
            state.status = 'error'
            state.data = null
        },

        [fetchAuthMe.pending] : (state) => {
            state.status = 'loading'
            state.data = null
        },

        [fetchAuthMe.fulfilled] : (state, action) => {
            state.status = 'loaded'
            state.data = action.payload
        },

        [fetchAuthMe.rejected] : (state) => {
            state.status = 'error'
            state.data = null
        },

        [fetchRegister.pending] : (state) => {
            state.status = 'loading'
            state.data = null
        },

        [fetchRegister.fulfilled] : (state, action) => {
            state.status = 'loaded'
            state.data = action.payload
        },

        [fetchRegister.rejected] : (state) => {
            state.status = 'error'
            state.data = null
        },

        [fetchUpdateMe.pending] : (state) => {
            state.status = 'loading'
        },

        [fetchUpdateMe.fulfilled] : (state, action) => {
            state.status = 'loaded'
            state.data = {...state.data, ...action.payload} 
        },

        [fetchUpdateMe.rejected] : (state) => {
            state.status = 'error'
        },

        [fetchUsers.pending] : (state) => {
            state.status = 'loading'
            state.data = null
        },

        [fetchUsers.fulfilled] : (state, action) => {
            state.status = 'loaded'
            state.data = action.payload
        },

        [fetchUsers.rejected] : (state) => {
            state.status = 'error'
            state.data = null
        },
        [fetchOneUser.pending] : (state) => {
            state.status = 'loading'
            state.data = null
        },

        [fetchOneUser.fulfilled] : (state, action) => {
            state.status = 'loaded'
            state.data = action.payload
        },

        [fetchOneUser.rejected] : (state) => {
            state.status = 'error'
            state.data = null
        },
    }
});

export const selectIsAuth = (state) => state.auth.data

export const authReducer = authSlice.reducer

export const { logout } = authSlice.actions