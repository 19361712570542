import styled from 'styled-components/macro'

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
`
export const Modal = styled.div`
    width: 860px;
    background: #FFFFFF;
    padding: 30px 0 30px;
    border-radius: 10px;

    @media (max-width: 577px){
        width: 362px;
        padding: 14px 0;
    }
`

export const ModalTopWrapper = styled.div`
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5);

    @media (max-width: 577px){
        padding-bottom: 0;
    }
`
export const ModalTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 45px;

    @media (max-width: 577px){
        padding: 0 17px 16px;
    }
`

export const ModalCloseBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`

export const ModalTitle = styled.div`
    font-weight: 400;
    font-size: 25px; 
    line-height: 25px;
    color: #000000;

    @media (max-width: 577px){
        font-size: 14px;
    }
`

export const ModalMain = styled.div`
   padding: 10px 60px 0 35px;
   margin-bottom: 10px;

   @media (max-width: 577px){
        padding: 26px 17px 0;
    }
`
export const ButtonSend = styled.button`
   border: none;
   cursor: pointer;
   padding: 7px 17px;
   text-align: center;
   height: 40px;
   background: 	rgba(254,97,3,0.15);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);       
   border-radius: 10px;   
   font-size: 1p4x;  
   color: #000000;
   
   @media (max-width: 577px){
        display: none;
    }
`

export const ButtonSendMobile = styled.button`
   display: none;
   
   @media (max-width: 577px){
        background: rgba(254,97,3,0.15);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 10px;  
        border: none;
        height: 25px;
        width: 100px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 60px 0 35px;

    @media (max-width: 577px){
        padding: 0 17px 0 0;
    }
`
export const Label = styled.label`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    @media (max-width: 577px){
        font-size: 12px;
        margin-bottom: 10px;
        display: block;
    }
`

export const LabelText = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    padding-top: 5px;

    @media (max-width: 577px){
        font-size: 13px;
        margin-bottom: 6px;
    }
`

export const Input = styled.input`
    box-sizing: border-box;
    padding: 7px 20px;
    width: 600px;
    height: 40px;
    border: 2px solid rgba(171, 154, 144, 0.50); 
    background: white;
    border-radius: 10px;
    outline: none;
    font-size: 16px;

    @media (max-width: 577px){
        font-size: 12px;
        width: 100%;
        height: 25px;
    }
`

export const Textarea = styled.textarea`
    font-size: 16px;
    box-sizing: border-box; 
    resize: none;
    padding: 10px 20px;
    width: 600px;
    height: 160px;
    border: 2px solid rgba(171, 154, 144, 0.50); 
    background: white;
    border-radius: 10px;
    outline: none;

    @media (max-width: 577px){
        font-size: 12px;
        width: 100%;
        height: 100px;
    }
`

export const RemarkBlock = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 60px 15px 35px;

    @media (max-width: 577px){
        font-size: 12px;
        padding: 20px 17px 0;
        align-items: flex-start;
    }
`

export const RemarkImg = styled.img`
    display: block;
    margin-right: 24px;
    width: 30px;

    @media (max-width: 577px){
        margin-right: 9px;
        width: 20px;
    }
`

export const StarsImg = styled.img`
    display: block;
    margin-right: 22px;
    width: 42px;

    @media (max-width: 577px){
        margin-right: 5px;
        width: 15px;
    }
`

export const RemarkText = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 25px; 

    @media (max-width: 577px){
        font-size: 13px;
        line-height: 15px; 
    }
`