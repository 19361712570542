import styled from 'styled-components/macro'
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import turnLeftIcon from './../../../assets/controls/turn-left.svg';
import turnRightIcon from './../../../assets/controls/turn-right.svg';
import clearIcon from './../../../assets/controls/clear.svg';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

const Image = styled.div`
    box-sizing: border-box;
    width: 170px;
    height: 280px;
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid rgba(171, 154, 144, 0.26);
    border-radius: 12px;
    touch-action: none;
    background: white;

    @media (max-width: 577px){
        width: 130px;
        height: 200px;
    }
`
const WorkImageWrapper = styled.div`
    width: 100%;
    flex: 1 1 100%;
    position: relative;
    display: flex;
`
const WorkImage = styled.img`
    width: 100%;
    border-radius: 10px;
    display: block;
    object-fit: cover;
    height: 100%;
    max-height: 226px;

    @media (max-width: 577px){
        max-height: 148px;
    }
`
const MainImageMsg = styled.div`
    position: absolute;
    left: 0;
    bottom: 14px;
    background: #F5F5F5; 
    border-radius: 0 9px 9px 0;
    color: #000;
    font-family: 'Montserrat', sans-serif; 
    font-size: 7px;
    height: 20px;
    padding: 0 7px;
    display: flex;
    align-items: center;
`
 const ControlPanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 18px 0;
    flex: 0 0 auto;
 `
 const ControlItem = styled.img`
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;

    @media (max-width: 577px){
        width: 14px;
        height: 14px;
    }
 `

const ImageWrapper = (props) => {
    const { t } = useTranslation();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    const deleteBtnClickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        props.deleteImg(props.id);
    }

    const turnImageRightHandler = () => {
        props.onImageRotate(props.id, 8);
    }

    const turnImageLeftHandler = () => {
        props.onImageRotate(props.id, 6);
    }

    return (
        <Image props={props} style={style} ref={setNodeRef} {...attributes} {...listeners}>
            <WorkImageWrapper>
                <WorkImage src={props.img || props.url} alt='uploaded' />
                {props.isMain && <MainImageMsg>{t('mainImage')}</MainImageMsg>}
            </WorkImageWrapper>
            <ControlPanel>
                <ControlItem alt='turn left' src={turnLeftIcon} onClick={turnImageRightHandler} />
                <ControlItem alt='turn right' src={turnRightIcon} onClick={turnImageLeftHandler} />
                <ControlItem onClick={deleteBtnClickHandler} alt='clear' src={clearIcon} />
            </ControlPanel>
        </Image>
    )
}

export default ImageWrapper;