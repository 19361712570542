import { forwardRef, useState } from 'react';
import { Input, ErrorText, Textarea } from './styles';

const CustomRulesTextField = forwardRef((props, ref) => {
    const [errors, setErrors] = useState([]);

    const changeHandler = (e) => {
        const nextErrors = (props.rules || []).filter((rule) => {
            switch(true) {
                case 'regex' in rule:  
                    return !rule.regex?.test(e.target.value)
                case 'isShort' in rule:
                    return rule.isShort > e.target.value.length
                default: return false
            }
        });
        setErrors(nextErrors);

        nextErrors.length
            ? props.onError && props.onError(nextErrors[0], e.target.value, e)
            : props.onChange && props.onChange(e.target.value, e)
    }

    return (
        <>
            {
                props.isTextarea ? 
                    <Textarea error={errors.length > 0} value={props.value} onChange={changeHandler} name={props.name} ref={ref} />
                        :
                    <Input error={errors.length > 0} type="text" size="2" value={props.value} onChange={changeHandler} name={props.name} ref={ref} />
            }
            {errors.length > 0 && errors[0].errorText && (
                <ErrorText marginLeft={props.marginLeft}>{errors[0].errorText}</ErrorText>
            )}
        </>
    )
})

export default CustomRulesTextField;