import React from 'react'
import styled from 'styled-components/macro'
import RegisterNav from '../../Register/RegisterNav'
import { Routes, Route} from 'react-router-dom'
import SignUp from '../../Register/Register'
import SignIn from '../../Register/SignIn'
import RegisterCollector from '../../Register/RegisterCollector'
import RegisterSeller from '../../Register/RegisterSeller'


const Section = styled.div`
width: 435px;
height: fit-content;
box-shadow: inset 0px 5px 3px -2px rgba(0, 0, 0, 0.25);
border: 1px solid rgba(189, 169, 158, 0.8);
border-radius: 25px;
margin: 5rem auto;
background-color: white;

@media (max-width: 577px){
  overflow-x: hidden;
  width: 100%;
}
`

const Register = () => {
  return (
    <Section>
      <RegisterNav />
      <main className='container'>
        <Routes>
          <Route index element={<SignUp />} />
          <Route path="register" element={<SignUp />}>
            <Route index element={<RegisterCollector/>} />
            <Route path="collector" element={<RegisterCollector/>} />
            <Route path="seller" element={<RegisterSeller/>} />
          </Route>
          <Route path="login" element={<SignIn />} />
        </Routes>
      </main>
    </Section>
  )
}

export {Register}