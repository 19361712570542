import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { CustomLink } from '../CustomLinkUser'
import { useTranslation } from 'react-i18next'

const Menu = styled.ul`
width: 825px;
display: flex;
list-style: none;
justify-content: space-between;
align-items: center;
top: 16rem;
left: 31rem;
font-weight: 400;
font-size: 18px;

@media (max-width: 577px){
  gap: 15px 27px;
  margin-bottom: 15px;
}

.MenuItem{
  width: 177px;
  height: 20px;
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 0.6rem 0.8rem;
  background-color: rgba(213, 200, 200, 0.6);
  font-weight: 400;
  font-family: 'Palatino';
}


.MenuItem img{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-bottom: -3px;
    opacity: 0.8;
}

@media (max-width: 577px){
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  left: 0;
  top: 25rem;
  position: relative;
  top: 0;

  @media (max-width: 384px){
  }
  .MenuItem{
    width: 140px;
  }
  .MenuItem img{
    display: none;
  }
}

@media (max-width: 384px){
  .MenuItem {
    max-width: 120px;
  }
}
`
const MenuItem = styled.li`
width: 150px;
height: 20px;
border: 1px solid #F5F5F5;
border-radius: 10px;
margin: 0 1rem;
justify-content: center;
text-align: center;
padding: 0.5rem 0.6rem 0.8rem;
background-color: rgba(213, 200, 200, 0.6);
font-weight: 400;
font-family: 'Palatino';

img{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-bottom: -3px;
    opacity: 0.8;
}

@media (max-width: 577px){

}
`


const OrderMenu = () => {

  const { t } = useTranslation()

  return (
    <Menu>
        <CustomLink className='MenuItem' to='/account'>{t('myOrder')}</CustomLink>
        {/* <CustomLink className='MenuItem' to='/account/Message'>Сообщения</CustomLink> */}
        <CustomLink className='MenuItem' to='/account/Message'>{t('messenger')}</CustomLink>
        <CustomLink className='MenuItem' to='/account/Subs'>{t('subscribed')}</CustomLink>
        {/* <CustomLink className='MenuItem' to='/account/New'>{t('whatsNew')}<img src="https://svgshare.com/i/iCa.svg" alt='bell' /></CustomLink> */}
        {/* <CustomLink className='MenuItem' to='/account/Subs'>{t('New')}</CustomLink> */}
    </Menu>
  )
}

export default OrderMenu