import styled from 'styled-components/macro';

export const Root = styled.div`
    box-sizing: border-box;
    padding: 65px 0px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1400px;

    ul {
        padding-left: 25px;
    }

    @media (max-width: 1024px){
        padding: 40px 10px;
    }

    @media (max-width: 414px){
        padding: 40px 10px;
    }
`

export const SectionMenu = styled.div`
    margin: 0 auto;
    border-bottom: 1px solid rgba(145, 114, 114, 0.2);
    width: 100%;
`

export const ContainerMenu = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`

export const Options = styled.div`
    padding-bottom: 8px;
    font-size: 32px;
    font-weight: 400;
    font-family: Palatino Linotype;
    color: rgba(0,0,0,0.80);
    opacity: 0.8;

    @media (max-width: 1024px){
        padding-bottom: 5px;
        font-size: 30px;
        font-family: Palatino Linotype;
    }
    @media (max-width: 414px){
        font-family: Palatino Linotype;
        font-size: 25px;
    }
`

export const SectionMasters = styled.div`
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    width: 100%;

    @media (max-width: 1024px){
        padding-top: 18px; 
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }

    @media (max-width: 414px){
        grid-template-columns: 1fr 1fr;
        gap: 7px;
    }
`

export const Card = styled.div`
    border-radius: 30px;
    border: 1px solid #D7D7D7; 
    padding: 20px 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    height: 345px;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1024px){
        height: 280px;
    }

    @media (max-width: 414px){
        padding: 12px 10px;
        height: 240px;
    }
`

export const Avatar = styled.div`
    border-radius: 50px;
    width: 100px;
    height: 100px;
    margin-bottom: 15px;

    @media (max-width: 1024px){
        width: 66px;
        height: 66px;
        margin-bottom: 10px;
    }

    @media (max-width: 414px){
        padding: 12px 10px;
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
    }
`

export const AvatarImg = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 1px solid #D7D7D7;
    object-fit: cover;

    @media (max-width: 1024px){
        width: 66px;
        height: 66px;
    }

    @media (max-width: 414px){
        width: 60px;
        height: 60px;
    }
`

export const MasterInfo = styled.div`
    margin-bottom: 16px;
    height: 68px;

    @media (max-width: 1024px){
        margin-bottom: 20px;
    }

    @media (max-width: 414px){
        margin-bottom: 10px;
    }
`

export const MasterInfoItem = styled.div`
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500; 
    opacity: 0.8; 
    text-align: center;

    @media (max-width: 414px){
        font-size: 10px;
    }
`

export const MasterShop = styled(MasterInfoItem)`
    font-size: 16px; 
    font-weight: 700; 

    @media (max-width: 1024px){
        font-size: 14px; 
    }

    @media (max-width: 414px){
        font-size: 13px; 
    }
`

export const MasterName = styled(MasterInfoItem)`
    @media (max-width: 1024px){
        font-size: 12px; 
    }

    @media (max-width: 414px){
        font-size: 10px; 
    }
`

export const MasterCountryContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const MasterCountryImg = styled.img`
    display: block;
    padding: 0;
    width: 13px;

    @media (max-width: 1024px){
        width: 10px;
    }

    @media (max-width: 414px){
        width: 9px;
    }
`

export const MasterCountry = styled(MasterInfoItem)`
    font-weight: 500; 

    @media (max-width: 1024px){
        font-size: 12px; 
    }

    @media (max-width: 414px){
        font-size: 10px; 
    }
`

export const MasterFollowers = styled(MasterInfoItem)`
    font-size: 12px; 

    @media (max-width: 1024px){
        font-size: 12px; 
    }

    @media (max-width: 414px){
        font-size: 10px; 
    }
`

export const WorksInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
`

export const WorksItem = styled.img`
    display: block;
    padding: 0;
    width: 95px;
    height: 95px; 
    border-radius: 20px;
    object-fit: cover;

    @media (max-width: 1024px){
        width: 74px;
        height: 74px; 
    }

    @media (max-width: 414px){
        width: 45px;
        height: 45px; 
        border-radius: 10px;
    }
`

export const WorksItemNumberContainer = styled.div`
    padding: 0;
    width: 95px;
    height: 95px; 
    border-radius: 20px;
    background-color: #F5F5F5;
    padding: 17px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px){
        width: 74px;
        height: 74px; 
    }

    @media (max-width: 414px){
        padding: 7px;
        width: 45px;
        height: 45px; 
        border-radius: 10px;
    }
`

export const WorksItemNumber = styled.div`
    font-size: 35px; 
    opacity: 0.8;

    @media (max-width: 1024px){
        font-size: 25px; 
    }

    @media (max-width: 414px){
        font-size: 18px; 
    }
`

export const WorksItemNumberText = styled.div`
    font-size: 20px; 
    opacity: 0.8;

    @media (max-width: 1024px){
        font-size: 14px; 
    }

    @media (max-width: 414px){
        font-size: 10px; 
    }
`

export const Filters = styled.div`
    position: absolute;
    right: 0;
    color: #917272; 
    font-size: 16px; 
`

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const SearchText = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 18px; 
    opacity: 0.8;

    @media (max-width: 1024px){
        font-size: 14px; 
    }

    @media (max-width: 414px){
        font-size: 13px;
        font-weight: 300;
        line-height: 10px;
    }
`

export const ArrowImg = styled.img`
    display: block;
    padding: 0;
    margin-left: 3px;
    width: 10px;

    @media (max-width: 414px){
        width: 8px;
    }
`