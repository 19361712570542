import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import arrowToShop from './../../assets/arrow-right.png'


const Btn = styled.button`
margin-top: 15px;
position: absolute;
width: 220px;
height: 36px;
background: rgba(189, 169, 158, 0.1);
display: flex;
align-items: center;
justify-content: center;
color:  #000;
outline: none;
border: 1px solid rgba(189, 200, 180, 0.1);
font-family: "Montserrat", sans-serif;
font-size: 16px;
font-weight: 500;
padding: 0.2rem 0.5rem;
border-radius: 10px;
right: 20px;
cursor: pointer;
opacity: 1;
  div{
    opacity: 0.9;
  }

@media (max-width: 577px){
  width: 120px;
  margin-left: 0;
  font-size: 12px;
  margin-top: 0;

  img{
    margin: 1px 0px;
  }
}
`

const ImgArrow = styled.img`
    width: 15px;
    height: 15px;
    margin-left: 10px;
    display: block;

    @media (max-width: 577px){
      margin: 0
    }
`


const ButtonMarket = () => {

  const { t } = useTranslation()

  return (
    <Btn>
       <div>{t('gotoshop')} </div><ImgArrow src={arrowToShop} alt="arrow"/>
    </Btn>
  )
}

export default ButtonMarket