import React from 'react'
import Orders from './Orders'

const OrderTable = () => {
  return (
    <>
        <Orders />
    </>
  )
}

export default OrderTable