import styled from 'styled-components/macro'

import messageSvg from './Message.svg';
import closeSVG from './close.svg';
import { useState } from 'react';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next'

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
`
const Modal = styled.div`
    width: 970px;
    background: #FFFFFF;
    padding: 30px 0 40px;
    border-radius: 10px;

    @media (max-width: 577px){
        width: 362px;
        padding: 14px 0;
    }
`

const ModalTopWrapper = styled.div`
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5);

    @media (max-width: 577px){
        padding-bottom: 0;
    }
`
const ModalTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    padding: 0 45px;

    @media (max-width: 577px){
        padding: 0 17px 16px;
    }
`

const ModalSendText = styled.div`
    display: flex;
`

const ModalCloseBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`
const ModalIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 577px){
        width: 15px;
    }
`
const ModalTitle = styled.div`
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: #000000;

    @media (max-width: 577px){
        font-size: 15px;
    }
`

const ModalMain = styled.div`
   padding: 40px 45px 0;

   @media (max-width: 577px){
        padding: 26px 17px 0;
    }
`

const ToAdress = styled.div`
   font-weight: 400;
    font-size: 22px;
    line-height: 34px;

    @media (max-width: 577px){
        font-size: 15px;
        line-height: 20px;
    }
`

const TextField = styled.textarea`
   resize: none;
   border: 2.05px solid rgba(171, 154, 144, 0.5);
   border-radius: 10px;
   height: 351px;
   width: 100%;
   margin-top: 20px;
   margin-bottom: 30px;
   padding: 20px;
   font-weight: 400;
   font-size: 22px;
   line-height: 34px;
   box-sizing: border-box;

   @media (max-width: 577px){
        height: 230px;
        margin-top: 21px;
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 14px;
    }
`
const ButtonContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`
const ButtonAttachment = styled.button`
   border: none;
   cursor: pointer;
   padding: 15px;
   text-align: center;
   height: 50px;
   width: 228px;
   background: rgba(148,147,147,0.15);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);
   border-radius: 10px;
   margin-right: 20px;
   font-size: 15px;

   @media (max-width: 577px){
        padding: 8px 30px;
        height: 30px;
        width: 136px;
        font-size: 13px;
        line-height: 16px;
    }
`
const ButtonSend = styled.button`
   border: none;
   cursor: pointer;
   padding: 15px;
   text-align: center;
   height: 50px;
   width: 228px;
   background: 	rgba(254,97,3,0.15);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);       
   border-radius: 10px;   
   font-size: 15px;  
   
   @media (max-width: 577px){
        padding: 8px 30px;
        height: 30px;
        width: 136px;
        font-size: 13px;
        line-height: 16px;
    }
`

const InputContainerStyled = styled.div`
  position: relative;

  &::file-selector-button {
    cursor: pointer;
  }
`

const InputStyled = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;

  &::file-selector-button {
    cursor: pointer;
  }
`

const MessageModal = ({to, theme, onClose, onSend, defaulValue = ''}) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [text, setText] = useState(defaulValue);
    const { t } = useTranslation();

    const attachFile = (e) => { 
        let form = new FormData()
        form.append('images', e.target.files[0])
  
        axios.post('/upload', form).then(({ data }) => {
          e.target.value = '';
  
          setFileUrl(data.urls[0]);
        });
      }

    return (
        <ModalWrapper>
            <Modal>
                <ModalTopWrapper>
                    <ModalTop>
                        <ModalSendText>
                            <ModalIcon>
                                <img alt='message' src={messageSvg} />
                            </ModalIcon>
                            <ModalTitle>
                                {t('sendMessage')}
                            </ModalTitle>
                        </ModalSendText>
                        <ModalCloseBtn onClick={onClose}>
                            <img alt='close' src={closeSVG} />
                        </ModalCloseBtn>
                    </ModalTop>
                </ModalTopWrapper>
                <ModalMain>
                    <ToAdress>
                        <b>{t('to')}</b> {to} 
                    </ToAdress>
                    <ToAdress>
                        <b>{t('theme')}</b> {theme}
                    </ToAdress>
                    <TextField
                        value={text}
                        onChange={(e) => {setText(e.target.value)}}
                    />
                    <ButtonContainer>
                        <InputContainerStyled>
                            <ButtonAttachment type='button'>{t('attach')}</ButtonAttachment>
                            <InputStyled name="attachment" type="file" accept="image/*" onChange={attachFile}/>
                        </InputContainerStyled>
                        <ButtonSend onClick={() => {onSend(text, fileUrl)}}>
                            {t('send')}
                        </ButtonSend>
                    </ButtonContainer>
                </ModalMain>
            </Modal>
        </ModalWrapper>
    )
}

export default MessageModal;