import React, { useState, useRef, useMemo, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigationType } from 'react-router-dom';
import burger from '../../assets/burger.png'
import axios from '../../utils/axios'
import { useTranslation } from 'react-i18next'
import defaultToy from './../../assets/defaultToy.png'
import defaultAvatar from './../../assets/cardPhoto.png'
import { selectIsAuth } from '../../redux/slices/auth'
import { getPagination, DOTS } from './get-pagination';
import CardPreview from '../Card/card-preview'
import LoaderCircle from '../../ui-kit/loader-circle/loader-circle'
import { cardsSelect, saveScrollPosition, setCathegory, setCurrentPage } from '../../redux/slices/cards';

const Section = styled.section`
max-width: 1200px;
margin: 0 auto;
padding: 0 20px;
height: fit-content;
overflow: hidden;
padding-bottom: 5rem;

@media (max-width: 577px){
  padding: 0 15px;
}
`

const MarketPart = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  justify-content: space-between;
  grid-gap: 30px;

  @media (max-width: 577px) {
    margin: 0;
    padding-top: 26px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    grid-template-rows: 269px;
    grid-auto-rows: 269px;

    & > a {
      overflow: hidden;
    }
  }
`

const SectionMenu = styled.div`
margin: 0 auto;
border-top: 1px solid rgba(145, 114, 114, 0.2);
border-bottom: 1px solid rgba(145, 114, 114, 0.2);
height: 70px;

width: 100%;
`

const TextContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 16px 0;
width: 100%;
position: relative;
`

const Text = styled.ul`
width: 280px;
list-style: none;
display: flex;
justify-content: space-between;
margin-top: -1rem;
cursor: pointer;
transition: 0.1s linear;

&:only-child:hover{
  border-bottom: 3px solid rgba(254, 97, 3, 0.3);
  transform: scale(1.1);
}

.true{
  border-bottom: 3px solid rgba(254, 97, 3, 0.3);
  transform: scale(1.1);
}


.false{
  opacity: 0.5;
}

.false:hover{
  border-bottom: 3px solid rgba(254, 97, 3, 0.3);
  transform: scale(1.1);
  opacity: 0.8;
}

@media (max-width: 577px){
  width: 200px;
  margin-left: 0;
}
`

const ItemMenu = styled.li`
font-family: 'Palatino';
font-size: 28px;
opacity: 0.75;
transition: 0.5s ease;

display: block;
padding-top: 16px;
height: fit-content;
width: fit-content;

`

const Filter = styled.div`
position: absolute;
display: flex;
right: 0;
margin-top: 5px;
font-family: 'Montserrat';
font-weight: 300;
font-size: 14px;

span {
  margin-top: 3px;
  margin-right: 10px;
}

img{
  opacity: 0.5;
  transform: scale(0.8);
}

@media (max-width: 577px){
  display: none;
}
`

const FilterPage = styled.div`
  margin-top: 1rem;
  height: fit-content;
  text-align: center;
  background-color: rgba(234, 226, 218, 0.6);
  border-radius: 15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (max-width: 577px){
    display: none;
  }
`

const Container = styled.div`
  margin-left: 0rem;
  font-size: 13pt;
  justify-content: center;
  align-items: center;
  display: flex;


  input[type='range']{
    background: white;
    width: 100px;
    color: white;
  }
  input[type='range']::-webkit-slider-thumb{
    background: white;
    color: white;
  }
`

const NumCont = styled.div`
  margin-left: 1rem;
  width: 90px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-right: 20px;
`

const Btn = styled.button`
  margin-right: 0.5rem;
  border: none;
  background-color: ${(props) => props.active ? 'rgba(213, 200, 200, 0.6)' : 'white'};
  font-family: 'Montserrat';
  font-size: 12pt;
  border-radius: 10px;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: fit-content;
  white-space: nowrap;
  border: 1px solid rgba(234, 226, 218, 1);
`

const BtnContainer = styled.div`
  display: flex;
  margin-left: 1rem;
`

const InputFilterPrice = styled.input`
  text-align: center;
  box-sizing: border-box;
  padding: 4px 3px;
  height: 20px;
  width: 55px;
  margin-right: 40px;
  margin-left: 10px;
  border-radius: 5px;
`

const InputFilterSize = styled.input`
  margin: 0;
  margin-right: 5px;
  text-align: center;
  box-sizing: border-box;
  padding: 4px 3px;
  height: 20px;
  width: 55px;
  border-radius: 5px;
`

const PaginationBtn = styled.button`
  width: 30px;
  height: 30px;
  color: #808183;
  border-bottom: 1px solid rgba(171, 154, 144, 0.3);  
  border-top: 1px solid rgba(171, 154, 144, 0.3);  
  border-right: 1px solid rgba(171, 154, 144, 0.3);  
  border-left: none;  
  cursor: pointer;
  background: transparent;
  opacity: 0.9;
  font-family: "Montserrat",sans-serif;
  display: block;
`

const PaginationBtnFirst = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  color: #808183;
  border: 1px solid rgba(145,114,114,0.2);
  cursor: pointer;
  background: transparent;
  border-radius: 10px 0 0 10px;
  opacity: 0.9;
`

const PaginationBtnActive = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  color: #FFFFFF;
  border: 1px solid rgba(145,114,114,0.2);  
  cursor: pointer;
  background: #FE6103;
  opacity: 0.35;
  font-family: "Montserrat",sans-serif;
`

const PaginationBtnLast = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  color: #808183;
  border-right: 1px solid rgba(145,114,114,0.2); 
  border-top: 1px solid rgba(145,114,114,0.2);
  border-bottom: 1px solid rgba(145,114,114,0.2);
  border-left: none;   
  cursor: pointer;
  background: transparent;
  border-radius: 0 10px 10px 0;
  opacity: 0.9;
`

const PaginationWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`

const maxPrice = '9999'
const minPrice = '1'

const maxSize = '150'
const minSize = '1'

const PAGE_SIZE = 44;

const Market = (e) => {
  const [isShown, setIsShown] = React.useState(false)
  const [cards, setCards] = React.useState([])
  const [pages, setPages] = useState(0)
  const [isInitialFetched, setIsInitialFetched] = useState(false);
  const isAuth = useSelector(selectIsAuth)
  const dispatch = useDispatch();
  const cardsState = useSelector(cardsSelect)
      
  const [size, setSize] = React.useState('0');
  const [price, setPrice] = React.useState('0');
  const [status, setStatus] = React.useState(null);
  const sectionRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navType = useNavigationType();

  const [filterValue, setFilterValue] = useState({
    size,
    price,
    status
  });

  const updateFilterValue = () => {
    setFilterValue({
      size,
      price,
      status
    })
  }

  const pagination = useMemo(() => getPagination({
    totalPageCount: pages,
    currentPage: cardsState.currentPage
  }), [cardsState.currentPage, pages]);

  const isChanged = filterValue.size !== size || filterValue.price !== price || filterValue.status !== status;

  const { t } = useTranslation()
 
  const fetchCards = async (nextPage, nextCathegory, isInitial) => {
    try {
        const { data } = await axios.get(`/market`, { params: {
          status,
          size_to: size === '0' ? null : size,
          price_to: price === '0' ? null : price,
          limit: PAGE_SIZE,
          page: nextPage || cardsState.currentPage,
          cathegory: nextCathegory || cardsState.cathegory
        }
    })
        setCards(data.items)
        setPages(data.pages)

        if (nextPage) {
          dispatch(setCurrentPage(nextPage))
        }
        
        if (isInitial) {
          setIsInitialFetched(true);
        }
    } catch (error) {
        console.log(error)
    }
  }

  const changePage = async(nextPage, nextCathegory) => {
    await fetchCards(nextPage, nextCathegory);

    sectionRef.current && window.scrollTo({ top: sectionRef.current.offsetTop })
  }

  const onCategoryChange = async(nextCathegory) => {
    dispatch(setCathegory(nextCathegory))
    setLoading(true)
    await fetchCards(1, nextCathegory)
    setLoading(false)
  }

  React.useEffect(() =>{
    fetchCards(undefined, undefined, true)
  }, []);

  const filteredCards = () => {
    updateFilterValue();
    fetchCards();
  }

  const changePrice = (e) => {
    setPrice(e.target.value)
  }

  const changeSize = (e) => {
    setSize(e.target.value)
  }

  const savePosition = () => {
    dispatch(saveScrollPosition(window.scrollY))
  }

  const handleScrollPosition = () => {
    const scrollPosition = cardsState.scrollPosition;

    if (scrollPosition && navType === 'POP') {
      window.scrollTo({top: scrollPosition, behavior: 'smooth'});
    }
    dispatch(saveScrollPosition(null))
  };

  useEffect(() => {
    isInitialFetched && handleScrollPosition();
  }, [isInitialFetched])
  
  return (
    <Section ref={sectionRef}>
      <SectionMenu>
      <TextContainer>
          <Text>
           <ItemMenu className={cardsState.cathegory === 'teddy'} onClick={() => {onCategoryChange('teddy')}}>{t('teddy')}</ItemMenu>
           <ItemMenu className={cardsState.cathegory === 'doll'} onClick={() => {onCategoryChange('doll')}}>{t('dolls')}</ItemMenu>
          </Text>
        <Filter>
          <span>{t('filter')}</span>
          <img src={burger} alt="burger" onClick={() =>{setIsShown(!isShown)}} />
        </Filter>
      </TextContainer>
      </SectionMenu>
      {isShown && 
        <FilterPage>
          <BtnContainer>
            <Btn onClick={() => setStatus(null)}  active={status === null}>{t('all')}</Btn>
            <Btn onClick={() => setStatus('3') } active={status === '3'}>
              {t('reserve')}
            </Btn>
            <Btn onClick={() => setStatus('2') } active={status === '2'}>
              {t('sold')}
            </Btn>
            <Btn onClick={() => setStatus('1') } active={status === '1'}>
              {t('forSale')}
            </Btn>
          </BtnContainer>
          <Container style={{marginLeft: '0.5rem'}}>
            {t('price')}
            <input style={{marginLeft: '1rem'}}
              type='range' 
              min={minPrice} 
              max={maxPrice}
              onChange={changePrice}
              value={price}
            />
            <InputFilterPrice value={price} type="number" onChange={changePrice} />
          </Container>
          <Container style={{marginLeft: '-2rem'}}>
            {t('size')} 
            <input style={{marginLeft: '1rem'}}
              type='range' 
              min={minSize} 
              max={maxSize} 
              onChange={changeSize}
              value={size}
            />
            <NumCont>
              <InputFilterSize value={size} type="number" onChange={changeSize} /> cm
            </NumCont>
          </Container>
          <Btn onClick={() => filteredCards()} style={{marginLeft: '-1rem'}} disabled={!isChanged}>
            {t('apply')}
          </Btn>
        </FilterPage>
      }
      {
        loading ? 
          <LoaderCircle/> 
            :   
          <MarketPart>
            {cards?.map((obj) => {
              return (  
                <div onClick={savePosition}>
                  <CardPreview 
                      key={obj._id}
                      altWork={obj.name} 
                      imageWork={obj.imgUrl ?`https://toobears.com/api/${obj.imgUrl[0]}` : defaultToy}
                      avatar={`https://toobears.com/api/${obj.author.avatarUrl}` || defaultAvatar}
                      shopName={obj.author.shopname || `${obj.author.username} ${obj.author.surname}`}
                      nameWork={obj.name}
                      price={obj.price + ' ' + obj.currency}
                      size={obj.size + 'cm'}
                      likeActive={obj.like.includes(isAuth && isAuth._id)}
                      status={obj.status}  
                      link={`/card/${obj._id}`}  
                  />
                </div>
              )})} 
          </MarketPart>
      }
      <PaginationWrapper>
          {pagination.length > 0 &&
            <>
              <PaginationBtnFirst onClick={() => changePage(1)} >{`<<`}</PaginationBtnFirst>
              {pagination.map((value) => 
                value === cardsState.currentPage
                  ? <PaginationBtnActive disabled>{value}</PaginationBtnActive>
                  : value === DOTS
                    ? <PaginationBtn>{DOTS}</PaginationBtn>
                    : <PaginationBtn onClick={() => changePage(value)}>{value}</PaginationBtn>
                )}
              <PaginationBtnLast onClick={() => changePage(pagination[pagination.length - 1])} >{`>>`}</PaginationBtnLast>
            </>
          }
          
      </PaginationWrapper>
    </Section>
  )
}


export default Market