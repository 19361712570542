import styled from 'styled-components/macro';

export const Title = styled.div`
    color: rgba(0, 0, 0, 0.80);
    font-family: Palatino Linotype;
    font-size: 32px;
    font-weight: 400;
    opacity: 0.8;
    margin-bottom: 16px;

    @media (max-width: 1024px){
        font-size: 22px;
        margin-bottom: 10px;
    }
    
    @media (max-width: 414px){
        font-size: 20px;
        margin-bottom: 5px;
    }
`

export const Border = styled.div`
    opacity: 0.5;
    background: rgba(148, 148, 148, 0.50);
    height: 1px;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 32px;

    @media (max-width: 414px){
        margin-bottom: 30px;
        margin-top: 0;
    }
`

export const Text = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    opacity: 0.8;

    @media (max-width: 1024px){
        font-size: 20px;
    }
    
    @media (max-width: 414px){
        font-size: 15px;
        line-height: 21px;
    }
`

export const Date = styled(Text)`
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-align: end;
    opacity: 0.6;
    margin-top: 16px;

    @media (max-width: 1024px){
        font-size: 18px;
        margin-bottom: 10px;
    }
    
    @media (max-width: 414px){
        font-size: 16px;
        line-height: 20px;
        margin-top: 5px;
    }
`

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

     @media (max-width: 414px){
        display: block;
    }
`

export const ImgContainer = styled.div`
    display: flex;
    align-items: center;
    width: 182px;
    margin-right: 42px;

    img {
        width: 182px;
        display: block;
    }
    
    @media (max-width: 414px){
        width: 100%;
        margin: 20px 0 32px;
        justify-content: center;
        img {
            width: 178px;
        }
    }
`

export const Btn = styled.a`
    font-size: 16px;
    line-height: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
    height: 50px;
    opacity: 0.8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 246px;
    background-color: #FFE7D9;
    margin: 40px auto 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media (max-width: 577px){
        padding: 9px 14px;
        font-size: 12px;
        line-height: 13px;
        height: 29px;
        width: 154px;
         margin: 20px auto 29px;
    }
`