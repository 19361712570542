
import React from 'react'
import styled from 'styled-components/macro'
import Chat from '../UserLK/Chat.js'

const Container = styled.div`
  margin-left: 20px;
  margin-top: -27px;

  @media (max-width: 577px) {
    margin-left: 0;
    margin-top:  0;
  }
`

const MessageAuthor = () => {
  return (
    <Container>
        <Chat />
    </Container>
  )
}

export default MessageAuthor