import React from 'react'
import styled from 'styled-components/macro'
import OrdersList from '../../OrderList';

const WrapperOrderList = styled.div`
    margin-left: 40px;

    @media (max-width: 577px){
        width: auto;
        margin-left: 4px;
    }
`

const Orders = () => {
  return (
    <WrapperOrderList>
        <OrdersList />
    </WrapperOrderList>
  )
}

export default Orders