import React from 'react'
import styled from 'styled-components/macro'
import {CustomLink} from '../CustomLink'
import { useTranslation } from 'react-i18next'

const Block = styled.div`
  background-color: rgba(213, 200, 200, 0.7);
  width: 437px;
  height: 77px;
  margin-left: -1px;
  border-radius: 25px 25px 0 0;
  top: 0px;

  @media (max-width: 577px){
      width: 100%;
      margin: 0;
      display: flex;
      align-items: flex-end;
  }
`
const Menu = styled.div`
  position: relative;
  width: 363px;
  height: 53px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 2.1rem;
  top: 1.4rem;
  font-family: 'Palatino';
  font-size: 25px;

  .MenuItem{
    margin: 0.6rem;
    padding: 0.75rem 2rem;
    border-radius: 10px 10px 0 0;
    background: transparent;
  }

  @media (max-width: 577px){
      position: inherit;
      width: 100%;
      margin: 0;
      gap: 10px;

      .MenuItem{
        margin: 0;
    }
  }
`

const RegisterNav = () => {

  const { t } = useTranslation()

  return (
    <Block>
        <Menu className='nav-item'>
            <CustomLink className='MenuItem' to='/auth/login'>
              {t('login')}
            </CustomLink>
            <CustomLink className='MenuItem' to='/auth/register'>
              {t('register')}
            </CustomLink>
        </Menu>
    </Block>
  )
}

export default RegisterNav