import React from 'react'
import Banner from '../components/MainPage/Banner'
import Market from '../components/MainPage/Market'
import styled from 'styled-components/macro'

const Section = styled.div`
  width: 100%;
  overflow-x: hidden;
`

// const OnlineShowAnoun = styled.div`
//   margin-bottom: 25px;
//   text-align: center;
//   background: linear-gradient(180deg, rgba(218, 218, 218, 0.00) 13.02%, rgba(232, 179, 241, 0.57) 45.21%, #9AC5F8 78.54%);
//   color: #F5F5F5;
//   font-family: Palatino Linotype;
//   font-size: 24px;
//   font-weight: 700;
//   line-height: 42px;
//   opacity: 0.8;
//   max-width: 1200px;
//   margin: 0 auto;
//   text-shadow: 2px 2px 2px black;

//   @media (max-width: 414px){
//     font-size: 16px;
//     line-height: 30px;
//   }
// `

const MainPage = () => {

  return (
      <Section>
        <Banner />
        <Market />
      </Section>
  )
}

export default MainPage