
import closeSVG from './../../components/MessageModal/close.svg';
import { useState } from 'react';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next'
import alarm from './alarm_on_24px.svg';
import {ButtonSend, ButtonSendMobile,ButtonWrapper, Input,
        Label, LabelText, Modal, ModalCloseBtn, ModalMain, ModalTitle,
        ModalTop, ModalTopWrapper, ModalWrapper, RemarkBlock, RemarkImg,
        RemarkText, Textarea, StarsImg } from './styles'


const ModalFeedBack = (props) => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const { t } = useTranslation();

    const changeEmail = (e) => {
        setEmail(e.target.value)
    }

    const changeName = (e) => {
        setName(e.target.value)
    }

    const changeMessage = (e) => {
        setMessage(e.target.value)
    }

    const sendEmail = async (e, name, email, message, url) => {
        e.preventDefault();
        e.stopPropagation();

        await axios.post(`/${url}`, { name, email, message }, { maxRedirects: 0 });
        alert(t('mesWasSent'));
        props.onClose()
    }

    return (
        <ModalWrapper>
            <Modal>
                <ModalTopWrapper>
                    <ModalTop>
                        <ModalTitle>
                            {props.title}
                        </ModalTitle>
                        <ModalCloseBtn onClick={props.onClose}>
                            <img alt='close' src={closeSVG} />
                        </ModalCloseBtn>
                    </ModalTop>
                </ModalTopWrapper>
                <RemarkBlock>
                    {props.image ? <StarsImg alt='stars' src={props.image}/> : <RemarkImg alt='icon alarm' src={alarm}/>}
                    <RemarkText>
                        {props.text}
                    </RemarkText>
                </RemarkBlock>
                <form onSubmit={(e) => sendEmail(e, name, email, message, props.url)}>
                    <ModalMain>
                        <Label>
                            <LabelText>
                                {t('fullName')}
                            </LabelText>
                            <Input value={name} type='text' onInput={changeName}/>
                        </Label>
                        <Label>
                            <LabelText>
                                {t('Email')}
                            </LabelText>
                            <Input value={email} type='email' onInput={changeEmail}/>
                        </Label>
                        <Label>
                            <LabelText>
                                {t('yourMes')}
                            </LabelText>
                            <Textarea value={message} type='text' onInput={changeMessage}/>
                        </Label>
                    </ModalMain>
                    <ButtonWrapper>
                        <ButtonSend type='submit'>{t('sendMessage')}</ButtonSend>
                        <ButtonSendMobile type='submit'>{t('send')}</ButtonSendMobile>
                    </ButtonWrapper>
                </form>
            </Modal>
        </ModalWrapper>
    )
}

export default ModalFeedBack;