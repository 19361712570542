import { useTranslation } from 'react-i18next'
import {
    Card, Avatar, AvatarImg, MasterInfo, MasterShop, MasterName, 
    MasterCountryContainer,MasterCountry, MasterCountryImg,
    MasterFollowers, WorksInfo, WorksItem, WorksItemNumber,
    WorksItemNumberContainer, WorksItemNumberText
} from '../styles';
import defaultAvatar from './../../../assets/cardPhoto.png'
import mark from './assets/mark.svg'
import { Link } from 'react-router-dom';

function getNoun(n, one, two, five) {
    switch(true) {
        case n >= 5 && n <= 20:
            return five
        case n === 1:
            return one
        case n >= 2 && n <= 4:
            return two
        default:
            return five
    }
}

const MastersCard = (props) => {
    const { t } = useTranslation();

    return (    
        <Link to={`/${props.link}`}>
            <Card>
                <Avatar>
                    <AvatarImg alt='avatar' src={props.avatar ? `https://toobears.com/api/${props.avatar}` : defaultAvatar}/>
                </Avatar>
                <MasterInfo>
                    <MasterShop>
                        {props.shopName}
                    </MasterShop>
                    <MasterName>
                        {props.name}
                    </MasterName>
                    {props.country &&
                        <MasterCountryContainer>
                        <MasterCountryImg alt='mark' src={mark}/>
                        <MasterCountry>{props.country}</MasterCountry>
                    </MasterCountryContainer>
                    }
                    <MasterFollowers>
                        {props.subs} {getNoun(Number(props.subs), t('followers1'), t('followers2'), t('followers3'))}
                    </MasterFollowers>
                </MasterInfo>
                <WorksInfo>
                    {props.workItemFirst && 
                        <WorksItem alt='first' src={`https://toobears.com/api/${props.workItemFirst}`}/>
                    }
                    {props.workItemSecond && 
                        <WorksItem alt='first' src={`https://toobears.com/api/${props.workItemSecond}`}/>
                    }
                    <WorksItemNumberContainer>
                        <WorksItemNumber>{props.numberWorks}</WorksItemNumber>
                        <WorksItemNumberText>{getNoun(Number(props.numberWorks), t('work1'), t('work2'), t('work3'))}</WorksItemNumberText>
                    </WorksItemNumberContainer>
                </WorksInfo>
            </Card>
        </Link>
    )
}

export default MastersCard;