import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, 
     MainTextTitle, ParticipantListTitle, 
      OurJury, OurJuryWrapper, OurJuryItem, JuryAvatar, JuryName,
      JuryCountry, JuryDesc, JuryCountryMark, JuryCountryName, JuryAvatarWrapper,
      JuryAvatarMaskRight, JuryAvatarMaskLeft,
      ShowByeTitle,
      JuryCircle
} from '../styles';
import MarkIcon from './assets/mark.svg';
import Second from './assets/tanya-blaauw-jury.png';
import First from './assets/joanne-mc-donald.png';
import JuryMaskRight from './assets/avatar-mask-stars-right.png';
import JuryMaskLeft from './assets/avatar-mask-stars-left.png';
import Rules from './rules-n-benefits/rules';
import Benefits from './rules-n-benefits/benefits';


const OnlineShowParticipant = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <BannerBear></BannerBear>
            <MainText>
                <MainTextTitle><i>{t('ToobearShow')}</i> - </MainTextTitle>
                {t('ToobearShowDesc')}
            </MainText>
            <MainText>{t('ShowDesc2')}</MainText>
            <OurJury>
                <ParticipantListTitle><i>{t('OurJury')}</i></ParticipantListTitle>
                <OurJuryWrapper>
                    <OurJuryItem>
                        <JuryAvatarWrapper>
                            <JuryAvatarMaskLeft alt='mask' src={JuryMaskLeft}/>
                            <JuryAvatar alt='avatar' src={First}/>
                        </JuryAvatarWrapper>
                        <JuryName>
                            {t('Joanne McDonald')}
                        </JuryName>
                        <JuryCountry>
                            <JuryCountryMark alt='country' src={MarkIcon}/>
                            <JuryCountryName>{t('UK')}</JuryCountryName>
                        </JuryCountry>
                        <JuryDesc>
                            {t('JoanneDesc')}
                        </JuryDesc>
                    </OurJuryItem>
                    <OurJuryItem>
                        <JuryAvatarWrapper>
                            <JuryAvatarMaskRight alt='mask' src={JuryMaskRight}/>
                            <JuryAvatar alt='avatar' src={Second} />
                        </JuryAvatarWrapper>
                        <JuryName>
                            {t('Tanya Blaauw')} 
                        </JuryName>
                        <JuryCountry>
                            <JuryCountryMark alt='country' src={MarkIcon}/>
                            <JuryCountryName>{t('Namibia')}</JuryCountryName>
                        </JuryCountry>
                        <JuryDesc>
                            {t('TanyaDesc')}
                        </JuryDesc>
                    </OurJuryItem>
                </OurJuryWrapper>
            </OurJury>
            <Rules/>
            <Benefits/>
            <ShowByeTitle>
                <i>{t('ByeShoe')}</i>
            </ShowByeTitle>
        </Root>
    )
}

export default OnlineShowParticipant;