import { useTranslation } from 'react-i18next'
import {Root, Title, Description, SubTitle, 
        BannerBear, SubTitleColor, AuthorBlock, AvatarBlock, TextBlock, 
        AuthorBlockWrapper, TeamBlock, TeamItem, TeamTitleDesigner, TeamTitleWithStarIllustrator, TeamTitleWithStarDeveloper
} from './styles';
import avatarMaria from './../../assets/avatar-maria.svg';

const OurHistory = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <BannerBear></BannerBear>
            <Title>{t('OurHistory')}</Title>
            <SubTitle><SubTitleColor>Toobears </SubTitleColor>{t('OHSubTitle1')}</SubTitle>
            <Description>
                {t('OHDesc1')}
                <br/>
                <br/>
                {t('OHDesc2')}
                <br/>
                <br/>
                {t('OHDesc3')}
            </Description>
            <SubTitle><SubTitleColor>Toobears </SubTitleColor>{t('OHSubTitle2')}</SubTitle>
            <AuthorBlockWrapper>
                <AuthorBlock>
                    <AvatarBlock>
                        <SubTitleColor>{t('OHSubTitle3')}</SubTitleColor>
                        <img alt='avatar' src={avatarMaria}/>
                    </AvatarBlock>
                    <TextBlock>
                        {t('OHDesc4')}
                        <br/>
                        <br/>
                        {t('OHDesc5')}
                        <br/>
                        <br/>
                        {t('OHDesc6')}
                        <br/>
                        <br/>
                        {t('OHDesc7')}
                    </TextBlock>
                </AuthorBlock>
                <TeamBlock>
                    <TeamItem>
                        <TeamTitleDesigner>
                            {t('OHSubTitle4')}
                        </TeamTitleDesigner>
                        {t('OHDesc8')}
                    </TeamItem>
                    <TeamItem>
                        <TeamTitleWithStarIllustrator>
                            {t('OHSubTitle5')}
                        </TeamTitleWithStarIllustrator>
                        {t('OHDesc9')}
                    </TeamItem>
                    <TeamItem>
                        <TeamTitleWithStarDeveloper>
                            {t('OHSubTitle6')}
                        </TeamTitleWithStarDeveloper>
                        {t('OHDesc10')}
                    </TeamItem>
                </TeamBlock>
            </AuthorBlockWrapper>
            <Description>
                {t('OHDesc11')}
                <br/>
                <br/>
                {t('OHDesc12')}
                <br/>
                <br/>
                {t('OHDesc13')}
                <br/>
                <br/>
                <SubTitleColor>Toobears</SubTitleColor>{t('OHDesc14')}
                <br/>
                <br/>
                {t('OHDesc15')}
            </Description>
        </Root>
    )
}

export default OurHistory;