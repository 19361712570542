import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import axios from '../../../utils/axios'
import { useTranslation } from 'react-i18next'

import defaultToy from './../../../assets/defaultToy.png'
import defaultAvatar from './../../../assets/cardPhoto.png'
import { selectIsAuth } from '../../../redux/slices/auth'
import CardPreview from '../../Card/card-preview';
import { useSelector } from 'react-redux'
import LoaderCircle from '../../../ui-kit/loader-circle/loader-circle'
import heart from './../../../assets/like.png'

const Card = styled.section`
    width: 900px;
    margin: 2rem 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    justify-content: center;

    @media (max-width: 577px){
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px 10px;
        justify-content: flex-start;
        padding: 0 5px;
        box-sizing: border-box;
    }
`

const Head = styled.div`
    width: 840px;
    font-weight: 400;
    font-size: 26px;
    display: flex;
    margin-left: 0.1rem;
    margin-top: -2rem;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5) ;
    font-family: 'Palatino';

    img{
        width: 30px;
        height: 30px;
        position: relative;
        opacity: 0.9;
        top: 2px;
        left: 10px;
    }
    @media (max-width: 577px){
        max-width: 91%;
        margin-left: 1.2rem;
        left: 0px;
    }
`

const CardBlock = () => {

    const [cards, setCards] = useState([])
    const { t } = useTranslation()
    const isAuth = useSelector(selectIsAuth)
    const [loading, setLoading] = useState(false);

    const fetchMyCards = async () => {
        try {
            setLoading(true)
            const { data } = await axios.get('/market/user/me/liked')
            setCards(data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMyCards()
    }, [])

  if (!cards){
      return(
      <div>Загрузка</div>)
    }

  return (
    <>
        <Head>
            {t('Likes')}<img src={heart} alt='heart' />
        </Head>
        {
            loading ? 
                <LoaderCircle/> 
            : 
                <Card>
                    {cards.filter((card) => !!card).reverse().map((card, index) => (
                        <CardPreview
                            altWork={card.name} 
                            imageWork={card.imgUrl ?`https://toobears.com/api/${card.imgUrl[0]}` : defaultToy}
                            avatar={`https://toobears.com/api/${card.author.avatarUrl}` || defaultAvatar}
                            shopName={card.author.shopname || `${card.author.username} ${card.author.surname}`}
                            nameWork={card.name}
                            price={card.price + ' ' + card.currency}
                            size={card.size + 'cm'}
                            likeActive={card.like.includes(isAuth && isAuth._id)}    
                            status={card.status}  
                            link={`/card/${card._id}`}
                            key={card._id}
                        />
                    ))}
                </Card>
        }
    </>
  )
}

export default CardBlock