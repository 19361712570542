import React from 'react';
import OrderMenu from './OrderMenu'
import OrderList from '../OrderList';

const Orders = () => {
  return (
    <div>
        <OrderMenu />
        <OrderList />
    </div>
  )
}

export default Orders