import React from 'react'
import Rive from 'rive-react'
import bear from '../assets/Bear1.riv'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'


const Section = styled.section`
height: 600px;
display: flex;
justify-content: center;
align-items: center;
`
const ImgCont = styled.div`
width: 450px;
height: 500px;
`
const TextCont = styled.div`
width: 700px;
height: 500px;
text-align: left;
font-size: 58px;

`
const Text = styled.h4`
margin: 7.5rem 0;
opacity: 1;
color: #B99D9D;
span{
  font-size: 46px;
  color: #D5C8C8;
}
`


const MissingPage = () => {
  return (
    <>
    <Section>
      <ImgCont>
        <Rive src={bear} width="500px" height="500px"/>
      </ImgCont>
      <TextCont>
      <Text> 
      There’s nothing here yet, but it will be very soon 
      <br />
      <Link to="/"><span>Go back</span></Link>
      </Text> 
      </TextCont>
    </Section>
    </>
  )
}

export default MissingPage