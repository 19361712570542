import { useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import { useCallback, useEffect, useState } from "react";
import MissingPage from "../../pages/MissingPage";
import CardInfo from "../UserPage/CardInfo";

const UserRouteCheckPage = () => {
    const {pathname} = useLocation();
    const [user, setUser] = useState();
    
    const fetchUser = useCallback(async () => {
        try {
            const { data } = await axios.get(`/users${pathname}`)
            setUser(data);
        } catch {
            setUser('error');
        }
    }, [pathname])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    return user === 'error' ? 
        (
            <MissingPage />
        ) 
            :
        user ? (
            <CardInfo user={user}/>
        )   
            :
        null
}

export default UserRouteCheckPage;