import React from 'react'
import styled from 'styled-components/macro'
import ButtonSave from './Settings/ButtonSave'
import Dest from './Settings/Dest'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUpdateMe, selectIsAuth } from '../../redux/slices/auth'
import axios from '../../utils/axios'
import { useTranslation } from 'react-i18next'

const Section = styled.section`
position: relative;
margin-left: 25px;

@media (max-width: 577px){
    overflow: hidden;
    margin-left: 0;
}
`

const Head = styled.div`
font-weight: 400;
font-size: 26px;
font-family: 'Palatino';
margin-top: 3rem;
padding-bottom: 0.5rem;
border-bottom: 1px solid rgba(148, 148, 148, 0.5);
@media (max-width: 577px){
    width: 90%;
    margin-left: 1rem;
}
`
const Block = styled.div`
margin-top: 2rem;
`

const FormBlock = styled.div`
position: relative;
padding-bottom: 1rem;
padding-top: 0rem;
width: 400px;
input{
    margin-top: 1rem;
    width: 500px;
    height: 35px;
    border: 1px solid #949494;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
}

@media (max-width: 577px){
    margin-left: 0.8rem;
    input{
        width: 90%;
       margin-left: 0rem;
    }
}
`
const Name = styled.h4`
font-size: 18px;
font-weight: 400;
`

const Btn = styled.button`
position: relative;
width: 210px;
height: 40px;
background-color: rgba(254, 97, 3, 0.2);
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 500;
font-size: 16px;
border-radius: 10px;
cursor: pointer;
left: 0rem;

@media (max-width: 577px){
    margin-left: 1rem;
    margin-top: 10px;
}
`

const Form = ({name = " "}) => {
    return(
        <FormBlock>
            <Name>{name}</Name>
            <input type="text" size="0" />
        </FormBlock>
    )
}

const Addition = () => {


    const dispatch = useDispatch()
    const { t } = useTranslation()

    const authInfo = useSelector(selectIsAuth)

    const {register, handleSubmit} = useForm({
        defaultValues:{
            fbUrl: authInfo.fbUrl !== 0 ? authInfo.fbUrl : '',
            tgUrl: authInfo.tgUrl !== 0 ? authInfo.tgUrl : '',
            instUrl: authInfo.instUrl !== 0 ? authInfo.instUrl : '',
            vkUrl: authInfo.vkUrl !== 0 ? authInfo.vkUrl : '',
        },
        mode: 'onChange'
      })
    
      const onSubmit = async (values) =>{
        const data = await dispatch(fetchUpdateMe(values))
    
        if (!data.payload) {
          return alert('Не удалось авторизоваться')
        }
    
        window.location.reload();
      }

  return (
    <>
    <Section>
    <Head>
       {t('web')}
    </Head>
    <form onSubmit={handleSubmit(onSubmit)}>
    <Block>
        <FormBlock>
            <Name>Facebook</Name>
            <input type="text" {...register('fbUrl')} />
        </FormBlock>
        <FormBlock>
            <Name>Telegram</Name>
            <input type="text" {...register('tgUrl')} />
        </FormBlock>
        <FormBlock>
            <Name>Instagram</Name>
            <input type="text"  {...register('instUrl')} />
        </FormBlock>
        <FormBlock>
            <Name>Vk</Name>
            <input type="text"  {...register('vkUrl')} />
        </FormBlock>

    </Block>
    {/* <Dest /> */}
    <Btn type='submit'>
        <span>{t('save')}</span>
    </Btn>
    </form>
    </Section>
    </>
  )
}

export default Addition