import { useTranslation } from 'react-i18next'
import {ShowListTitle, ShowList, ShowListItem, ShowListItemGreen, ShowListSmall, ShowListItemNotPoint } from './../../styles';

const Rules = () => {
    const { t } = useTranslation();

    return (
      <>
        <ShowListTitle><i>{t('ShowRules')}</i></ShowListTitle>
        <ShowList>
            <ShowListItem><i><b>{t('ShowItem1/2')}</b></i>{t('ShowItem2/2')}</ShowListItem>
            <ShowListItem>{t('ShowItem2-1/2')}<ShowListItemGreen><i><b>{t('ShowItem2-2/2')}</b></i></ShowListItemGreen></ShowListItem>
            <ShowListItem>{t('ShowItem3')}</ShowListItem>
            <ShowListItem>{t('ShowItem4')}</ShowListItem>
            <ShowListItem>{t('ShowItemPrice')}</ShowListItem>
            <ShowListItem><b><i>{t('ShowItem5-1/2')}</i>{t('ShowItem5-2/2')}</b></ShowListItem>
            <ShowListItem>{t('ShowItem6')}</ShowListItem>
            <ShowListItem>{t('ShowItem7')}</ShowListItem>
            <ShowListItem>{t('ShowItem8')}</ShowListItem>
            <ShowListItem>{t('ShowItem9-1/2')}<b><i>{t('ShowItem9-2/2')}</i></b></ShowListItem>
            <ShowListSmall>
                <ShowListItem>{t('ShowItemSmall1')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall2')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall3')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall4')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall5')}</ShowListItem>
            </ShowListSmall>
 
            <ShowListItem>{t('ShowItem10-1/3')}<b><i>{t('ShowItem10-2/3')}</i></b>{t('ShowItem10-3/3')}</ShowListItem>
            <ShowListItem>{t('ShowItem11')}</ShowListItem>
        </ShowList>
      </> 
    )
}

export default Rules;