export const OLD_KEYS_COUNTRY_MAP = {
    'Сербия': 'RS',
    'Serbia': 'RS',
    'Russia':  'RU',
    'Россия':  'RU',
    'Kazakhstan': 'KZ',
    'Казахстан': 'KZ',
    'Belarus': 'BY',
    'Беларусь': 'BY',
    'China': 'CN',
    'Китай': 'CN',
    'USA': 'US',
    'США': 'US'
  }