import React from 'react'
import styled from 'styled-components/macro'
import { NavLink, Outlet} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Head = styled.h5`
font-family: 'Montserrat';
font-weight: 400;
font-size: 17px;
margin: 32px 60px 0;
`

const ItemSwitch = styled.div`
    padding: 0 95px;
    margin-bottom: 25px;
    height: auto;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.1s linear;

    &:only-child:hover{
      border-bottom: 3px solid rgba(254, 97, 3, 0.3);
      transform: scale(1.1);
    }

    .isActiveTab{
      border-bottom: 3px solid rgba(254, 97, 3, 0.3);
      transform: scale(1.1);
    }

    .isnotActiveTab{
      opacity: 0.5;
    }

    .false:hover{
      border-bottom: 3px solid rgba(254, 97, 3, 0.3);
      transform: scale(1.1);
      opacity: 0.8;
    }

    @media (max-width: 577px){
      margin: 0 60px;
      padding: 0;
    }
`

const ItemMenu = styled.div`
font-family: 'Montserrat';
font-size: 15px;
transition: 0.5s ease;
font-weight: 400;
display: block;
padding-top: 16px;
height: fit-content;
width: fit-content;

`


const Register = () => {
    const { t } = useTranslation()

  return (
    <>
        <Head>{t('im')}</Head>
        <ItemSwitch>
            <NavLink to='' end
                className={({isActive}) => isActive ? 'isActiveTab' : 'isnotActiveTab'}
            >
                <ItemMenu>{t('client')}</ItemMenu>
            </NavLink> 
            <NavLink to='seller'  
                className={({isActive}) => isActive ? 'isActiveTab' : 'isnotActiveTab'}
            >
                <ItemMenu>{t('author')}</ItemMenu>
            </NavLink>
        </ItemSwitch>
        <Outlet />
    </>
  )
}

export default Register