import styled from 'styled-components/macro';

export const ToastWrapper = styled.div`
    padding: ${props => props.isRed ? '3px 13px 6px 17px' : '8px 13px 27px 17px'}; 
    background: ${props => props.isRed ? 'rgba(251, 8, 8, 0.35)' : 'rgba(57, 105, 176, 0.2)'};
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: ${props => props.marginTop ? '10px' : '0'};

    @media (max-width: 414px){
        padding: 9px 7px 15px;
    }   
`

export const ToastTop = styled.div`
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const ToastCloseImg = styled.img`
    display: ${props => props.withoutClose ? 'none' : 'block'}; 
    width: 6px;
    cursor: pointer;
`

export const ToastMain = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

export const ToastNotificationImg = styled.img`
    display: block;
    width: 13px;
    cursor: pointer;
    margin-right: 11px;

    @media (max-width: 414px){
        width: 9px;
        margin-right: 6px;
    }   
`

export const ToastInfo = styled.div`
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Montserrat';
    opacity: 0.8;

    @media (max-width: 414px){
        font-size: 10px;
        line-height: 13px;
    }   
`