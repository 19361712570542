import CardBlock from './CardBlock';
import { useState } from 'react';
import {     useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react';
import { useEffect } from 'react';
import axios from "../../utils/axios";
import styled from 'styled-components/macro'
import { selectIsAuth } from '../../redux/slices/auth'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVk, faFacebook, faTelegram, faInstagram}from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'react-i18next'
import avatar from '../../assets/cardPhoto.png'
import MessageModal from '../MessageModal/MessageModal';
import { OLD_KEYS_COUNTRY_MAP } from '../../const/old-keys-country-map/old-keys-country-map';
import countriesLanguages from '@hotosm/iso-countries-languages'
import pin from './../../assets/pin.svg'
import heart from './../../assets/like.png'


const BannerCard = styled.div`
height: fit-content;
position: absolute;
top: -99px;
left: 0;
right: 0;
max-width: 1400px;
width: 100%;
margin: auto;

img{
  height: fit-content;
  width:  fit-content;
  max-width: 1400px;
  max-height: 255px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 577px){
  width: 100%;
  top: -65px;

  img{
    max-width: 100%;
    height: 130px;
    object-fit: cover;
    width: 100%;
  }
}
`
const Section = styled.section`
display: flex;
flex-direction: column;
position: relative;
background-color: #fff;
border: 1px solid rgba(171, 154, 144, 0.6);
border-radius: 20px;
max-width: 220px;
padding: 0 15px 32px;
box-sizing: border-box;

@media (max-width: 577px){
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
`
const ImgContainer = styled.div`
position: relative;
margin: 32px auto 10px;

img{
    width: 108px;
    height: 108px;
    border-radius: 50%;
    object-fit: cover;
}
`
const TextBlock = styled.div`
display: flex;
flex-direction: column;
margin: 0 auto;
line-height: 25px;
justify-content: center;
text-align: center;

`
const ShopName = styled.h3`
margin: 0 auto 5px;
font-size: 16px;
opacity: 0.8;
word-break: break-word;
`

const SellerName = styled.h3`
font-size: 14px;
font-weight: 400;
`

const ShopLocation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        width: 12px;
        height: 12px;
    }
    h4{
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 13px;
    }

    div {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
const Subs = styled.h3`
font-size: 13px;
font-weight: 400;
`

const Description = styled.section`
margin-left: 25px;
max-width: 870px;

@media (max-width: 577px){
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 1.2rem;
    text-align: match-parent;
}
`
const Head = styled.h2`
font-family: 'Palatino';
font-weight: 400;
`
const Body = styled.p`
    margin-top: 1.7rem;
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 400;
    white-space: pre-wrap;
    
    @media (max-width: 517px){
       word-break: break-word;
    }
`
const ButtonBlock = styled.div`
    margin: 0 auto;
    height: fit-content;
`

const Btn = styled.button`
    width: 146px;
    height: 30px;
    background-color: ${props => props.unsubBackground ? 'rgba(207,157,128,0.2)' : 'rgba(254, 97, 3, 0.2)'};
    color:  #000;
    outline: none;
    border: 0px solid #808080;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;

    img{
        display: block;
        width: 14px;
        opacity: 0.8
    }
    div {
        opacity: 0.8
    }
`

const BtnMessage =styled.button`
    border: none;
    border-radius: 10px;
    width: 146px;
    height: 30px;
    background: rgba(213, 200, 200, 0.6);
    opacity: 0.7;   
    color: #000000;
    margin-bottom: 18px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
`

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LeftSideStyled = styled.div`
    flex: 1 1 100%;
    margin-top: 161px;

    @media (max-width: 577px) {
        margin-top: 0;
    }
`

const Socials = styled.div`
font-size: 21pt;
color: grey;
margin: 0 auto 10px;
transition: 0.4s;

a {
    margin-right: 5px;
    &:last-child {
        margin-right: 0;
    }
}

.fb{
    transition: 0.4s ease;
    width: 30px;
    height: 30px;
}
.fb:hover{
    size: 1.2;
    color: #375d99;
}

.tg{
    transition: 0.4s ease;
    width: 30px;
    height: 30px;
}
.tg:hover{
    size: 1.2;
    color: #27a6e5;
}

.inst{
    transition: 0.8s ease;
    width: 30px;
    height: 30px;
    border-radius: 5px;
}
.inst:hover{
    size: 1.2;
    color: white;

    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.vk{
    transition: 0.4s ease;
    width: 30px;
    height: 30px;
}
.vk:hover{
    size: 1.2;
    color: #2c8eff;
}
`

const ContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1200px;
  margin: 100px auto 30px;
  padding-left: 40px;


  @media (max-width: 577px) {
    display: block;
    margin-top: 65px;
    padding-left: 0;
  }
`

function isValidUrl(str) {
    try {
      new URL(str);
      return true;
    } catch (err) {
      return false;
    }
  }

function parseUrl(str) {
    const isValid = isValidUrl(str);

    return isValid ? str : `http://${str}`;
}

const CardInfo = (props) => {
    const isAuth = useSelector(selectIsAuth)
    const { t, i18n  } = useTranslation()
    const [user, setUser] = useState(props.user)
    const params = useParams()
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [subs, setSubs] = useState([])
    const fetchOneUser = useCallback(async () => {
        if(params.id) {
            const { data } = await axios.get(`/users/${params.id}`)
            setUser(data)
        }
    }, [params.id])

    const country = countriesLanguages.getCountry(i18n.language, OLD_KEYS_COUNTRY_MAP[props.user.country] || props.user.country);
    
    const translatedCountry = props.user?.country ? (country || t('russia')) : t('russia')

    useEffect(() => {
        !user &&  fetchOneUser()
    }, [fetchOneUser, user])


    const fetchMySubs = async () => {
        try{
            const { data } = await axios.get(`users/me/subscribed`)
            setSubs(data)
        } catch (error) {
            console.log(error)
        }
      }
    
      useEffect(() => {
        fetchMySubs();
      }, [])

    const isSub = subs.find((item) => item._id === user?._id);

    const handleClick = async () => {
        if (isSub) {
            await axios.delete(`/users/subscribe/${user?._id}`)
            fetchMySubs()
        } else { 
            await axios.patch(`/users/subscribe/${user?._id}`)
            if (isAuth.role === 'User') {
                navigate('/account/Subs');
              } else {
                navigate('/account/Favorite/subs');
              }
        }
    }

    const navigateToLogin = () => {
        navigate('/auth/login');
    }

    if (!user) {
        return (
          <div>Загрузка</div>
        )
    }

      const sendMessage = async (text, attachment) => {
          await axios.post('/chats', {
            user: user._id,
            text,
            attachment
          });
    
        navigate('/account/Message');
        }

    return (
        <>
            <ContainerStyled>
                <BannerCard>
                    {user.bannerUrl &&
                        <img src={`https://toobears.com/api/${user.bannerUrl}`} alt='banner'/>
                    }
                </BannerCard>
                <Section>
                    <ImgContainer>
                    {user.avatarUrl != ('' || undefined || null) ?
                        <img src={`https://toobears.com/api/${user.avatarUrl}`} alt='banner'/>
                        :
                        <img src={avatar} alt='banner' />
                    }
                    </ImgContainer>
                    <TextBlock>
                        <ShopName>{user.shopname}</ShopName>
                        <SellerName>{user.username} {user.surname}</SellerName>
                        {user.country &&
                            <ShopLocation>
                                <img src={pin} alt="location"/>
                                <div>{translatedCountry}</div>
                            </ShopLocation>
                        }
                        <Subs>
                            {user.subsCount} {t('subs')}
                        </Subs>
                        <Socials>
                            {user.fbUrl &&
                                <a href={parseUrl(user.fbUrl)} target='_blank' rel="noreferrer" >
                                    <FontAwesomeIcon className='fb' icon={faFacebook} />
                                </a>
                            }
                            {user.tgUrl &&
                                <a href={parseUrl(user.tgUrl)} target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className='tg' icon={faTelegram} />
                                </a>
                            }
                            {user.instUrl &&
                                <a href={parseUrl(user.instUrl)} target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className='inst' icon={faInstagram} />
                                </a>
                            }
                            {user.vkUrl &&
                                <a href={parseUrl(user.vkUrl)} target='_blank' rel="noreferrer">
                                    <FontAwesomeIcon className='vk' icon={faVk} />
                                </a>
                            }
                        </Socials>
                    </TextBlock>
                    <ButtonBlock social={!!user.fbUrl | !!user.tgUrl | !!user.instUrl | !!user.vkUrl}>
                        {isAuth === null  ? 
                            <Btn onClick={navigateToLogin}>
                                <img src={heart} alt='heart' />&nbsp;
                                <div>{t('subscribe')}</div>
                            </Btn> 
                        : 
                        (isAuth._id === user._id ? 
                        <></>
                        :
                        <BtnWrapper>
                            <BtnMessage onClick={() => {setOpenModal(true)}}>
                                {t('write')}
                            </BtnMessage>
                            <Btn unsubBackground={isSub} onClick={handleClick}>
                                <div>{isSub ? t('unsub') : t('subscribe')}</div>
                            </Btn> 
                        </BtnWrapper>
                        )}
                    </ButtonBlock>
                </Section>
                <LeftSideStyled>
                    <Description>
                        <Head>{user.status}</Head>
                        <Body>{user.description}</Body>
                    </Description>
                </LeftSideStyled>
            </ContainerStyled>
            <CardBlock id={user.shortlink || user._id} />
            {openModal &&
                <MessageModal
                    onSend={sendMessage}
                    defaulValue=''
                    to={`${user.username} ${user.surname}`}
                    theme={user.shopname}
                    onClose={() => {setOpenModal(false)}}
                />
            }
        </>
    );

}

export default CardInfo