import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAuth, selectIsAuth } from '../../redux/slices/auth'
import { useTranslation } from 'react-i18next'
import ResetPassModal from '../reset-pass-modal/ResetPassModal'
import lock from './../../assets/lock.png'


const Root = styled.div`

`

const FormBlock = styled.div`
    position: relative;
    padding-bottom: 1rem;
    padding-top: 0rem;
    margin: 1rem 2.4rem;
    width: 400px;
    input{
        margin-top: 1rem;
        width: 350px;
        height: 35px;
        border: 1px solid #f1f1f1;
        background: #f1f1f1;
        border-radius: 5px;
        outline: none;
        text-indent: 10px;
    }

    @media (max-width: 577px){
        width: auto;

        input {
            width: 100%;
        }
    }
`
const Name = styled.label`
    font-weight: 400;
    font-size: 17px;
    position: relative;
    display: block;
    img{
        height: 20px;
        width: 20px;
        opacity: 0.5;
        position: absolute;
        right: 14px;
        top: 43px;
    }
`
const PassBlock = styled.div`
    margin: 1rem 2.4rem;
    input{
        margin-top: 1rem;
        width: 350px;
        height: 35px;
        border: 1px solid #f1f1f1;
        background: #f1f1f1;
        border-radius: 5px;
        outline: none;
        text-indent: 10px;
    }

    @media (max-width: 577px){
        input {
            width: 100%;
        }
    }
`

const BtnSubmit = styled.button`
    position: relative;
    width: 150px;
    height: 46px;
    background-color: rgba(254, 97, 3, 0.2);
    color:  #000;
    outline: none;
    border: 0px solid #808080;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 2.4rem;
    margin-top: 1rem;

    @media (max-width: 577px){
        margin: 0;
    }
`
const CheckBoxes = styled.div`
    display: flex;
    position: relative;
    left: 1px;
    padding-bottom: 1rem;

    @media (max-width: 577px){  
        justify-content:flex-start;
        gap: 15px;
        margin: 1rem 2.4rem;
    }
`
const Check = styled.div`
    margin: 1.5em 2rem;

    input{
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    input+label{
        display: inline-flex;
        align-items: center;
        user-select: none;
    }
    input+label::before{
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        background-color: #f1f1f1;
        border-radius: 0.25em;
        margin-right: 0.7em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    input:not(:disabled):not(:checked)+label:hover::before {
        border-color: rgba(254, 97, 3, 0.2);
    }
    input:not(:disabled):active+label::before{
        background-color: #EAE2DA;
        border-color: #EAE2DA;
    }
    input:focus:not(:checked)+label::before{
        border-color: #EAE2DA;
    }
    input:checked+label::before{
        border-color: #949494;
        background-color: rgba(254, 97, 3, 0.2);
        background-image: url(https://svgshare.com/i/iBL.svg);
    }
    input:disabled+label::before{
        background-color: rgba(254, 97, 3, 0.2);
    }

    @media (max-width: 577px){
        margin: 0;
        display: flex;
    }
`
const Block = styled.div`
    width: 437px;
    border-top: 1px solid rgba(171, 154, 144, 0.6);
    height: 82px;
    margin-top: 1.6rem;
    margin-left: -1px;

    @media (max-width: 577px){
        width: 100%;
    }
`
const Text = styled.button`
    background: none;
    border: none;
    width: 300px;
    padding: 1rem 2.4rem;
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;

    @media (max-width: 577px){
        width: auto;
    }
`


const SignIn = () => {
  const isAuth = useSelector(selectIsAuth)
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowResetPassModal, setIsShowResetPassModal] = useState(false);
  const dispatch = useDispatch()
  const {register, handleSubmit, setError, formState : {errors, isValid}} = useForm({
    defaultValues:{
        email: '',
        password: ''
    },
    mode: 'onChange'
  });

  const closeResetPassModal = () => {
    setIsShowResetPassModal(false);
  }

  const onSubmit = async (values) => {
    const data = await dispatch(fetchAuth(values))

    if (!data.payload) {
        return alert('Не удалось авторизоваться')
    }

    if ('token' in data.payload){
        window.localStorage.setItem('token', data.payload.token)
    }
  }

  if (isAuth){
    window.location.assign('/account')
  }

  const { t } = useTranslation()

  return (
    <Root>
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormBlock>
            <Name>{t('login2')}</Name>
            <input 
            type="email" 
            size="0" 
            id="email"
            {...register('email', { required: 'Укажите почту'})}
            />
        </FormBlock>
        <PassBlock>
            <Name>
                {t('password')}
                <input 
                    type={isShowPass ? "text" : "password"} 
                    size="0" 
                    id="password"
                    name="password"
                    {...register('password', { required: 'Укажите пароль'})}
                    placeholder={t('noless')} 
                />
                <img alt='lock' onClick={() => setIsShowPass(!isShowPass)} src={lock} title='lock' />
            </Name>
        </PassBlock>
        <CheckBoxes>

        <BtnSubmit type="submit">
                <span>{t('login')}</span>
        </BtnSubmit>
    
        <Check>
            <input type="checkbox" id='check' name='check'/>
            <label htmlFor='check'>{t('remember')}</label>
        </Check>
        </CheckBoxes>
        </form>
        <Block>
            <Text onClick={() => setIsShowResetPassModal(true)}>
                {t('reset')}
            </Text>
        </Block>
        {isShowResetPassModal && 
            <ResetPassModal
                onClose={() => closeResetPassModal()}
            />
        }
    </Root>
  )
}

export default SignIn