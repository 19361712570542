import { NavLink } from 'react-router-dom';

const CustomLink = ({children, to, ...props}) => {

    return (
        <NavLink    
            to={to}
            style={({ isActive }) => ({
                backgroundColor: isActive ? 'white' : 'transparent'
            })}
            {...props}
        >
            {children}
        </NavLink>

    )
}

export {CustomLink}