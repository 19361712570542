import React from 'react'
import StatusText from './StatusText'
import ActivityText from './ActivityText'


const Homepage = () => {
  return (
    <>
      <StatusText />
      <ActivityText />
    </>
  )
}

export default Homepage