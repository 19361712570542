import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
    position: absolute;
    top: 40px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    z-index: 99999;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    box-sizing: border-box;

    @media (max-width: 414px){
        padding: 11px 12px;
    }
`
export const Modal = styled.div`
    width: 400px;

    @media (max-width: 1024px){
        width: 340px;
    }

    @media (max-width: 414px){
        width: 230px;
    }
`

export const ModalTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 14px;

    @media (max-width: 1024px){
        margin-bottom: 16px;
    }

    @media (max-width: 414px){
        margin-bottom: 8px;
    }
`

export const SearchIcon = styled.img`
    display: block;
    padding: 0;
    margin: 4px;
    width: 19px;

    @media (max-width: 1024px){
        width: 15px;
    }

    @media (max-width: 414px){
        width: 11px;
    }
`

export const ModalTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    font-family: 'Montserrat';
    color: #010101; 
    opacity: 0.7;

    @media (max-width: 1024px){
        font-size: 15px; 
        line-height: 16px;
    }

    @media (max-width: 414px){
        font-size: 11px; 
        line-height: 12px;
    }
`

export const Input = styled.input`
    padding: 8px 12px;
    border-radius: 10px;
    border: 1px solid #D7D7D7; 
    font-size: 15x;
    line-height: 25px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    font-family: Montserrat; 
    color: rgba(0, 0, 0, 0.50); 

    @media (max-width: 1024px){
        padding: 11px 14px;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
    }

    @media (max-width: 414px){
        padding: 7px 10px;
        font-size: 8px;
        line-height: 8px;
    }
`
