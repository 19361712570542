import React from 'react'
import BannerRedact from './BannerRedact'

const HomepageRedact = () => {
  return (
    <>
    <BannerRedact />
    </>
  )
}

export default HomepageRedact