import React from 'react'
import styled from 'styled-components/macro'
import ButtonAll from './ButtonAll'
import ButtonSold from './ButtonSold'
import CardBlock from './CardBlock'
import Order from './Order'

const Section = styled.section`
width: 1000px;
position: relative;
margin-left: 40px;
margin-top: -40px;

@media (max-width: 577px){
  margin-left: 0;
  width: 100%;
}
`

const WrapperCardBlock = styled.div`

@media (max-width: 577px){
  margin-top: 40px;
}
`


const SoldBlock = () => {
  return (
    <Section>
        <WrapperCardBlock>
          <CardBlock />
        </WrapperCardBlock>
    </Section>
  )
}

export default SoldBlock