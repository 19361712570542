import React from 'react'
import styled from 'styled-components/macro'

const Btn = styled.button`
width: 85px;
height: 30px;
background: transparent;
display: inline-block;
color: #000;
outline: none;
border: 1px solid #949494;
font-family: "Montserrat", sans-serif;
font-weight: 600;
border-radius: 50px;
cursor: pointer;
opacity: 0.8;
margin-right: 1rem;

span{
  text-transform: capitalize;
}

@media (max-width: 577px){
  margin-right: 0.5rem;
}
`


const Button = ({text, link}) => {
  return (
    <Btn>
        <span target="_blank" rel="noreferrer">
            {text}
        </span>
    </Btn>
  )
}

export default Button