import React from 'react'
import styled from 'styled-components/macro'

const Text = styled.div`
margin-bottom: 0.5rem;
margin-left: 43.5rem;
width: 159px;
border-bottom: 1px solid #E2D9D9;
font-size: 16px;

`

const Order = () => {
  return (
    <Text>
        Изменить порядок
    </Text>
  )
}

export default Order